import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate,useLocation  } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMobileAlt } from "react-icons/fa";
import * as apicall from '../../components/Apicall';

const CouponsList = () => {

   const navigate = useNavigate();
   const location = useLocation();

    const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || '');
    const [statusFilter, setStatusFilter] = useState(location.state?.statusFilter || null);
    const [publicFilter, setPublicFilter] = useState(location.state?.publicFilter || null);
    const [filterApplied, setFilterApplied] = useState(Boolean(location.state?.statusFilter || location.state?.publicFilter));
    const [showFilters, setShowFilters] = useState(Boolean(location.state?.statusFilter || location.state?.publicFilter));
    const [coupons, setCoupons] = useState([]);
    const [loadingCoupons, setLoadingCoupons] = useState(true);
    const [role, setRole] = useState(localStorage.getItem("role"));


  const couponHeaders = [
    'Coupon Code',
    'Promotion',
    'Valid From',
    'Valid Until',
    '% OFF',
    'Is Active',
    'Is Public',
  ];

  const statusList = [{ "name": "Active", "value": 1 }, { "name": "In Active", "value": 0 }];
  const publicList = [{ "name": "Yes", "value": true }, { "name": "No", "value": false }];

  const getCouponsList = async (data) => {
    setLoadingCoupons(true);
    try {
      const res = await apicall.post(data, 'get_all_coupons');
      if (res.success) {
        setCoupons(res.data);
      } else {
        toast.error('Failed to fetch coupons.');
      }
    } catch (e) {
      toast.error(e.message.replace('Error:', ''));
    } finally {
      setLoadingCoupons(false);
    }
  };

  useEffect(() => {
    //getCouponsList({});
    if (searchTerm == "") {
      applyFilters();
    }
  }, [searchTerm]);

  const handleEdit = (index) => {
    const coupon = coupons[index];
        navigate(`/couponDetails/${coupon.id}`,
           { state: { couponId: coupon.id ,coupon, searchTerm, statusFilter, publicFilter, filterApplied  } });
      };
      

  const applyFilters = () => {
    var data = {};
    if (statusFilter != null) {
      data.is_active = statusFilter.value;
      setFilterApplied(true);
    }
    if (publicFilter != null) {
      data.is_public = publicFilter.value;
      setFilterApplied(true);
    }
    if (searchTerm != '') {
      const searchTermLower = searchTerm.trim().toLowerCase();
      data.keyword = searchTermLower
    }
    getCouponsList(data);

  };

  const clearAllFilters = () => {
    var data = {}
    setStatusFilter(null);
    setPublicFilter(null);
    setFilterApplied(false);
    setShowFilters(false);
    if (searchTerm != '') {
      const searchTermLower = searchTerm.trim().toLowerCase();
      data.keyword = searchTermLower
    }
    getCouponsList(data);
  };

  const toggleFilters = () => setShowFilters(prev => !prev);

  const couponsData = coupons.map(coupon => [
    coupon.code,
    coupon.description,
    new Date(coupon.start_date).toLocaleDateString(),
    new Date(coupon.end_date).toLocaleDateString(),
    `${coupon.value}%`,
    coupon.is_active ? 'Yes' : 'No',
    <span>
      <FaMobileAlt style={{ color: coupon.app_display ? '#d01c4c' : 'grey' }} />
    </span>,
  ]);

  return (
    <div className="vendor-table-container container mt-3">
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h3>Coupons</h3>
          <p>Here is a list of all Coupons</p>
        </div>

        <div className='mx-5 mb-4'>
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: '350px' }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  applyFilters();
                }
              }}
            />
            <InputGroup.Text className='primaryBackground' onClick={applyFilters}>
              <FaSearch className='text-white' />
            </InputGroup.Text>
          </InputGroup>
        </div>

        <div className="d-flex align-items-center mb-3">
          <img
            className='mx-2'
            src="/image/filter_active_icon.png"
            alt="Filter Applied"
            style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
          />
          <img
            className='mx-2'
            src="/image/filter_iocn.png"
            alt="Filter"
            style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
            onClick={toggleFilters}
          />
          {role == 1 &&
            <button className="btn Button primaryBackground text-white" onClick={() => navigate('/coupon/0')}>
              Add Coupons
            </button>
          }
        </div>
      </div>

      {showFilters && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex gap-3">
            <DropdownButton id="status-filter" variant="outline-secondary" title={statusFilter?.name || 'Status'}>
              {statusList.map(status => (
                <Dropdown.Item key={status.name} onClick={() => setStatusFilter(status)}>
                  {status.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            <DropdownButton id="public-filter" variant="outline-secondary" title={publicFilter?.name || 'Public'}>
              {publicList.map(pub => (
                <Dropdown.Item key={pub.name} onClick={() => setPublicFilter(pub)}>
                  {pub.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            <button className="btn Button primaryBackground text-white" onClick={applyFilters}>Apply</button>
            <button className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={clearAllFilters}>Clear All</button>
          </div>
        </div>
      )}

      {loadingCoupons ? (
        <Skeleton count={5} height={40} />
      ) : (
        <Table headers={couponHeaders} data={couponsData} pagination={true} recordsCount={10} details={handleEdit} />
      )}
    </div>
  );
};

export default CouponsList;