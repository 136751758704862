import { useEffect, useState,useCallback } from 'react';
import Loader from '../../components/Loader';
import { useNavigate} from "react-router-dom";
import * as apicall from '../../components/Apicall';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import {toast } from 'react-toastify';

const Login = () => {

    const [step, setStep] = useState(1);
    const [fullNumber,setFullNumber] = useState("")
    const [phoneNumber,setPhoneNumber] = useState("")
    const [countryCode,setCountryCode] = useState("")
    const [loader, setLoader] = useState(false);
    const [isNumberValid,setIsNumberValid] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [otp, setOtp] = useState('');
    const [count, setCount] = useState(120);
    const [otpRes,setOtpRes] = useState({});
    const [resendEnabled, setResendEnabled] = useState(false);

    const navigate = useNavigate();

    const numberOnChanges = async (value, country, formattedValue) => {
        setFullNumber(value)
        var mobile_number_length = country.format.split(".").length - 1;
        if(value.length == mobile_number_length){
            setIsNumberValid(true);
            setErrorMessage("");
            setCountryCode(country.dialCode);
            setPhoneNumber(value.replace(country.dialCode,'')?.trim())
        }else{
            setIsNumberValid(false)
        }
    }

    const getOTP = async () => {
        if(isNumberValid){
            try{
                setLoader(true);
                setCount(120);
                setResendEnabled(false);
                var data = {
                    mobileNumber:fullNumber,
                    is_mobile:false
                }
                let res = await apicall.post(data,"generateOTP");
                setOtpRes(res);
                setLoader(false);
                setStep(2);
            }catch(e){
                toast.error(e.message.replace('Error:',''))
                setLoader(false);
            }
        }else{
            setErrorMessage("Please enter correct phone number.")
        }
    }

    const verifyOtp = async () => {
        if(otp.length == 6){
            try{
            setLoader(true);
            var data = {
                mobileNumber:fullNumber,
                otp:otp,
                twilioService_id:otpRes.twilioService_id,
                user_id:otpRes.user_id,
                role:otpRes.role
            }
            let res = await apicall.verifyOTP(data);
            navigate('/services');
            }catch(e){
                toast.error(e.message.replace('Error:',''))
                setLoader(false);
            }
        }else{
            setErrorMessage("Please enter correct OTP.")
        }
    }

    const isUserLoggedIn = useCallback(async () => {
        const user_data = localStorage.getItem("token")
        if(user_data != undefined && user_data != null){
                navigate('/services');
        }
    })

    useEffect(() => {
        isUserLoggedIn();
       }, []);

       useEffect(() => {
        if (step === 2 && count > 0) {
            const timer = setTimeout(() => setCount(prevCount => prevCount - 1), 1000);
            return () => clearTimeout(timer);
        } else if (count === 0) {
            setResendEnabled(true); 
        }
    }, [count, step]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="container pt-5">
            <div className="d-flex justify-content-center pt-5">
                <div className="shadow-lg bg-body rounded sm-w-100" style={{width:'500px'}}>
                    <div className="text-center">
                        <img src="/image/logo.png" alt="Logo" width={'60%'}/>
                    </div>

                    <div className="p-5 pt-1">

                        {step == 1 ? 
                           (<div>
                                <div class="">
                                    <label htmlFor="phone" className="label">Enter Mobile Number</label>
                                    <PhoneInput
                                        onlyCountries={['in','ae']}
                                        country={'in'}
                                        value={fullNumber}
                                        onChange={(value, country,formattedValue) => numberOnChanges(value, country,formattedValue)}
                                        inputStyle={{width:"100%"}}
                                    />
                                    <div class="label text-danger">{errorMessage}</div>              
                                </div>
                                            
                                <div className="text-center pt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-block primaryBackground text-white"
                                        onClick={()=>getOTP()}
                                        >
                                            {loader ? <Loader style={{ width: 40, height: 20 }} /> : "Get OTP"}
                                    </button>
                                </div>
                            </div>)

                            :
                            (<div>
                                 <div class="greyColor">Enter the code we just sent on your registred phone</div>
                                 <div class="primaryColorText">+{countryCode} {phoneNumber}</div>

                                 <div class="d-flex justify-content-center">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderSeparator={<span>&nbsp;&nbsp;</span>}
                                        renderInput={(props) => <input {...props} />}
                                        containerStyle={{width:"70%",height:'100px',alignItems:"center"}}
                                        inputStyle={{width:"40%",height:"50%"}}
                                    />
                                 </div>
                                 <div class="label text-danger text-center">{errorMessage}</div>  
                                 <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-block primaryBackground text-white"
                                        onClick={()=>verifyOtp()}
                                        >
                                            {loader ? <Loader style={{ width: 40, height: 20 }} /> : "Login"}
                                    </button>
                                </div>
                                {resendEnabled ? (
                                    <div
                                        className="text-center pt-2 primaryColorText"
                                        onClick={() => getOTP()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <u>Resend OTP</u>
                                    </div>
                                ):
                                <div className="greyColor text-center pt-2">Resend OTP in <span className="primaryColorText">{formatTime(count)}</span></div>
                                }
                            </div>)
}
                            </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
