import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const BookingsList = () => {
    const [bookings, setBookings] = useState([]);
    const [loadingBookings, setLoadingBookings] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState(null);
    const [serviceFilter, setServiceFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [services, setServices] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const navigate = useNavigate();

    const bookingHeaders = [
        'Service Name',
        'Booking ID',
        'Customer Name',
        'Vendor Name',
        'Service Date',
        'Status',
    ];

    const statusList = [
        { name: "Complete", value: "complete" },
        { name: "In Process", value: "in-process" },
        { name: "Canceled", value: "canceled" }
    ];

    const getServiceList = async () => {
        try {
            const res = await apicall.get("services");
            setServices(res.data);
        } catch (e) {
            toast.error(e.message.replace('Error:', ''));
        }
    };

    const getBookingsList = async (data) => {
        setLoadingBookings(true);
        try {
            const res = await apicall.post(data, 'get_all_bookings');
            if (res.success) {
                setBookings(res.data);
            } else {
                toast.error('Failed to fetch bookings.');
            }
        } catch (e) {
            toast.error(e.message.replace('Error:', ''));
        } finally {
            setLoadingBookings(false);
        }
    };

    useEffect(() => {
        getBookingsList({});
        getServiceList();
    }, []);

    const handleEdit = (index) => {
        const booking = bookings[index];
        navigate(`/bookingDetails/${booking.id}`, { state: { bookingId: booking.id } });
    };

    const applyFilters = () => {
        let data = {};
        if (statusFilter != null) data.status = statusFilter.value;
        if (serviceFilter != null) data.service = serviceFilter.value;
        if (dateFilter != null) data.service_date = dateFilter.toISOString().split('T')[0];
        if (searchTerm.trim() !== '') data.keyword = searchTerm.trim().toLowerCase();

        setFilterApplied(true);
        getBookingsList(data);
    };

    const clearAllFilters = () => {
        setStatusFilter(null);
        setServiceFilter(null);
        setDateFilter(null);
        setSearchTerm('');
        setFilterApplied(false);
        getBookingsList({});
        setShowFilters(false);
    };

    const toggleFilters = () => setShowFilters(prev => !prev);

    const bookingsData = bookings.map((booking, index) => [
        booking.service_name,
        booking.booking_id,
        booking.customer_name,
        booking.vendor_name,
        new Date(booking.service_date).toLocaleDateString(),
        booking.status === 'complete' ? 'Complete' : booking.status === 'canceled' ? 'Canceled' : 'In Process',
    ]);

    return (
        <div className="container mt-3">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div>
                    <h3>Bookings</h3>
                    <p className='content mb-3 label'>Here is a list of all Bookings</p>
                </div>

                <div className='mx-5 mb-4'>
                    <InputGroup>
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            style={{ width: '350px' }}
                        />
                        <InputGroup.Text className='primaryBackground' onClick={applyFilters}>
                            <FaSearch className='text-white' />
                        </InputGroup.Text>
                    </InputGroup>
                </div>

                <div className="d-flex align-items-center mb-3">
                    <img
                        className='mx-2'
                        src="/image/filter_active_icon.png"
                        alt="Filter Applied"
                        style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                    <img
                        className='mx-2'
                        src="/image/filter_iocn.png"
                        alt="Filter"
                        style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                </div>
            </div>

            {showFilters && (
                <div className="mb-3">
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col className="d-flex flex-grow-1">
                            <DropdownButton id="service-filter" variant="outline-secondary" title={serviceFilter?.name || 'Select Service'} className="me-2">
                                {services.map(service => (
                                    <Dropdown.Item key={service.name} onClick={() => setServiceFilter(service)}>
                                        {service.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>

                            <DropdownButton id="status-filter" variant="outline-secondary" title={statusFilter?.name || 'Select Status'} className="me-2">

                                {statusList.map(status => (
                                    <Dropdown.Item key={status.name} onClick={() => setStatusFilter(status)}>
                                        {status.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>

                            <DropdownButton id="date-filter" variant="outline-secondary" title={dateFilter ? dateFilter.toLocaleDateString() : 'Booking Date'} className="me-2">
                                <Dropdown.Item className="p-0">

                                    <DatePicker
                                        selected={dateFilter}
                                        onChange={date => setDateFilter(date)}
                                        className="form-control"
                                        placeholderText="Select Booking Date"
                                        dateFormat="yyyy/MM/dd"
                                        isClearable
                                    />
                                </Dropdown.Item>
                            </DropdownButton>

                            <button className="btn Button primaryBackground text-white mx-2" onClick={applyFilters}>Apply</button>
                            <button className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={clearAllFilters}>Clear All</button>
                        </Col>
                    </Row>
                </div>
            )}

            {loadingBookings ? (
                <Skeleton count={5} height={40} />
            ) : (
                <Table headers={bookingHeaders} data={bookingsData} pagination={true} recordsCount={10} details={handleEdit} />
            )}
        </div>
    );
};

export default BookingsList;