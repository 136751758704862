import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

const InquirieList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [searchKey, setSearchKey] = useState(location.state?.searchKey || '');
    const [inquiryType, setInquiryType] = useState(location.state?.inquiryType || null);
    const [filterApplied, setFilterApplied] = useState(Boolean(location.state?.inquiryType));
    const [showFilters, setShowFilters] = useState(Boolean(location.state?.inquiryType));
    const [inquiries, setInquiries] = useState([]);
    const [loadingInquiries, setLoadingInquiries] = useState(true);
    
    const inquiryHeaders = ['Name', 'Mobile', 'Email', 'Inquiry Type'];
    const inquiryTypes = [
        { name: 'General Inquiries', value: 1 },
        { name: 'Partner Inquiries', value: 2 },
        { name: 'Career Inquiries', value: 3 }
    ];

    useEffect(() => {
        applyFilters();
    }, []);

    const getInquiriesList = async (data) => {
        setLoadingInquiries(true);
        try {
            const res = await apicall.post(data, 'inquiry');
            setInquiries(res.data || []);
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching inquiries.');
        } finally {
            setLoadingInquiries(false);
        }
    };

    const applyFilters = () => {
        const data = {};
        if (searchKey) data.search_key = searchKey;
        if (inquiryType) data.type = inquiryType.value;
        
        setFilterApplied(Boolean(inquiryType));
        setShowFilters(Boolean(inquiryType));

        getInquiriesList(data);
    };

    const clearAllFilters = () => {
        setInquiryType(null);
        setSearchKey('');
        setFilterApplied(false);
        setShowFilters(false);
        
        getInquiriesList({});
    };

    const toggleFilters = () => setShowFilters(prev => !prev);

    const handleDetails = (index) => {
        const inquiry = inquiries[index];
        navigate(`/inquiry/${inquiry.id}`, {
            state: { inquiry, searchKey, inquiryType, filterApplied }
        });
    };

    const formattedInquiries = inquiries.map(inquiry => [
        inquiry.name,
        inquiry.mobile,
        inquiry.email,
        (inquiry.type === 1 ? "General" : inquiry.type === 2 ? "Partner" : "Career") + " Inquiry"
    ]);

    return (
        <div className="container my-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="heading ml-2">Inquiries</h3>
                <div className='mx-5 mb-4 d-flex justify-content-center flex-grow-1'>
                    <Form onSubmit={(e) => { e.preventDefault(); applyFilters(); }}>
                        <InputGroup>
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                                style={{ width: '350px' }}
                            />
                            <InputGroup.Text className='primaryBackground' onClick={applyFilters}>
                                <FaSearch className='text-white' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <img
                        className='mx-2'
                        src="/image/filter_active_icon.png"
                        alt="Filter Applied"
                        style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                    <img
                        className='mx-2'
                        src="/image/filter_iocn.png"
                        alt="Filter"
                        style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                </div>
            </div>

            {showFilters && (
                <div className="mb-3 d-flex">
                    <DropdownButton
                        id="inquiry-type-filter"
                        variant="outline-secondary"
                        title={inquiryType?.name || 'Select Inquiry Type'}
                        className="mx-2 Button"
                    >
                        {inquiryTypes.map(type => (
                            <Dropdown.Item key={type.value} onClick={() => setInquiryType(type)}>
                                {type.name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <div className='mx-4'>
                        <button className="btn Button primaryBackground text-white mx-4" onClick={applyFilters}>Apply</button>
                        <button className="btn Button text-black primaryColorText btn-outline-secondary no-hover-effect" onClick={clearAllFilters}>Clear All</button>
                    </div>
                </div>
            )}

            {loadingInquiries ? (
                <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
            ) : (
                <Table
                    headers={inquiryHeaders}
                    data={formattedInquiries}
                    pagination={true}
                    recordsCount={10}
                    details={handleDetails}
                />
            )}
        </div>
    );
};

export default InquirieList;