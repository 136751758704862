import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import Loader from '../../components/Loader';
import Skeleton from 'react-loading-skeleton';
import * as apicall from '../../components/Apicall';

function VendorForm2({ vendorData, vendor_id, prev, onSubmit }) {

  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [pricingFormdata, setPricingFormdata] = useState([]);
  const [priceError, setPriceError] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [months, setMonths] = useState([
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ])



  const fetchPricingDetails = async (month) => {
    try {
      setSelectedMonth(month)
      setPricingFormdata([])
      if (month == 0 || month != "") {
        var offering_ids = "";
        for (const file of vendorData.serviceOffering) {
          offering_ids = offering_ids + (offering_ids == "" ? `` : `,`) + file.id;
        }
        var data = {
          vendor_id: vendor_id,
          month: month,
          offerings: offering_ids
        }
        setSkeletonLoading(true)
        const res = await apicall.post(data, "vendorEditPrice");
        setPricingFormdata(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      setSkeletonLoading(false);
    }
  };

  const change_price = async (type_index, price_index, type, value) => {
    var temp = [...pricingFormdata];
    if (type == "fixed") {
      temp[type_index].priceDetails[price_index].fixed_cost = value
    } else {
      temp[type_index].priceDetails[price_index].unit_cost = value
    }
    setPricingFormdata(temp)
  }

  const submit = async () => {
    var service_offerings_price = [];
    setPriceError("");
    setLoading(true);

    var is_price_valid = true;
    for (const d of pricingFormdata) {
      for (const item of d.priceDetails) {
        if (item.fixed_cost <= 0 || item.unit_cost <= 0) {
          is_price_valid = false;
          setPriceError("Price value must be greater than 0");
          setLoading(false);
        }
      }
    }

    if (is_price_valid) {
      for (const d of pricingFormdata) {
        for (const item of d.priceDetails) {
          var obj = {
            price_id: item.pricing_id,
            service_offering_id: d.id,
            service_tier: item.service_tier,
            fixed_price: item.fixed_cost,
            unit_price: item.unit_cost
          }
          service_offerings_price.push(obj)
        }
      }
      var finaldata = {
        month: selectedMonth,
        service_offerings_price: service_offerings_price
      }
      setTimeout(() => {
        setLoading(false);
      }, 10000);
      onSubmit(finaldata)
    }
  }

  useEffect(() => {
    if (vendor_id == 0 || vendor_id == "0") {
      fetchPricingDetails(0);
    }
  }, []);


  return (
    <div className="container">
      <h3 className='heading'>Fill Offering Prices</h3>

      <p className='label'>Enter the prices for every offering across all months.</p>

      {vendor_id != 0 &&
        <div className="mb-3 d-flex flex-row">
          <label htmlFor="membershipType" className="label mt-2 me-2">Select Month:</label>
          <select
            id="months"
            name="months"
            value={selectedMonth}
            onChange={(e) => { fetchPricingDetails(e.target.value) }}
            style={{ width: '190px' }}
          >
            <option value="">Select a month</option>
            {months.map(plan => (
              <option key={plan.id} value={plan.id}>{plan.name}</option>
            ))}
          </select>
        </div>
      }

      {skeletonLoading ? (
        <Skeleton count={4} height={100} />
      ) : (
        <Formik
          initialValues={{
            standardFixedPrice1BHK: '',
            standardPerKmPrice1BHK: '',
            premiumFixedPrice1BHK: '',
            premiumPerKmPrice1BHK: '',
          }}
          onSubmit={(values) => {
            submit()
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {pricingFormdata.length > 0 && (
                <Row className="mb-4">
                  {pricingFormdata.map((item, index) => (
                    <Col md={12} key={item.id}>
                      <div className="border rounded p-3 bg-light mb-3">
                        <h4>{item.name}</h4>
                        {item.priceDetails.map((price, p_i) => (
                          <Row key={p_i}>
                            <Col md={6}>
                              <Form.Label className='label'>Enter {price.service_tier_name} Fixed Price</Form.Label>
                              <InputGroup>
                                <InputGroup.Text>د.إ</InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  name="standardFixedPrice1BHK"
                                  value={price.fixed_cost}
                                  onChange={(e) => change_price(index, p_i, "fixed", e.target.value)}
                                />
                              </InputGroup>
                            </Col>
                            <Col md={6}>
                              <Form.Label className='label'>Enter {price.service_tier_name} Per KM Price</Form.Label>
                              <InputGroup>
                                <InputGroup.Text>د.إ</InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  name="standardPerKmPrice1BHK"
                                  value={price.unit_cost}
                                  onChange={(e) => change_price(index, p_i, "unit", e.target.value)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}

              <div className='d-flex justify-content-center text-danger'>{priceError}</div>


              <div className='d-flex justify-content-center mt-5 mb-4'>
                <button
                  type="button"
                  className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
                  onClick={prev}
                >
                  Back
                </button>
                {pricingFormdata.length > 0 &&
                  < button
                    type="submit"
                    className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"

                  >
                    {loading ? <Loader style={{ height: "30", width: "40" }} /> : 'Save'}
                  </button>
                }
              </div>
            </Form>
          )}
        </Formik>
      )
      }
    </div >
  );
}

export default VendorForm2;