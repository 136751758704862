import React, { useState, useEffect } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import VendorForm1 from './VendorForm1';
import VendorForm2 from './VendorForm2';
import * as apicall from '../../components/Apicall';
import Skeleton from 'react-loading-skeleton';

const VendortSignUp = () => {

    const navigate = useNavigate();
    const { vendor_id } = useParams();
    const [step, setStep] = useState(1);
    const [is_display,setIs_display] = useState(false);
    const [vendorData, setVendorData] = useState({});

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const setData = async (data) => {
        setVendorData(data)
        nextStep()
    };

    const save = async (pricingData) =>{
        try{
        var data = {
            name:vendorData.vendorName,
            service_type:vendorData.serviceType,
            mobile:vendorData.contactNumber,
            country_id:vendorData.country,
            state_id:vendorData.state,
            city_id:vendorData.city,
            email:vendorData.email,
            address:vendorData.address,
            memberShip_type:vendorData.membershipType,
            start_date:vendorData.membershipStartDate,
            end_date:vendorData.membershipEndDate,
            bank_name:vendorData.bankName,
            account_number:vendorData.bankAccountNumber,
            bank_ifsc:vendorData.bankIFSC,
            name_on_bank:vendorData.nameAsPerBank,
            tradeLicense_number:vendorData.tradersLicenseNumber,
            license_exp_date:vendorData.licenseExpDate,
            month:pricingData.month,
            service_offerings_price:pricingData.service_offerings_price
        }

        var res = await apicall.post(data, 'vendor/'+vendor_id);
        if(res.success){
            toast.success(res.message);
            navigate('/vendors')
        }else{
            toast.error(res.message);
        }

    }catch (error) {
        toast.error(error.message.replace('Error:',''))
      } finally {
        //setLoading(false);
      }

    }

    const getVendorDetails = async () => {
        try {
            const res = await apicall.get("vendorDetails/"+vendor_id);
            var v_data = res.data[0];
            var d = {
                vendorName: v_data.vendor_name,
                service_name:v_data.service_name,
                serviceType: v_data.service_id,
                contactNumber: v_data.phone_number,
                country_name : v_data.country_name,
                country: v_data.country_id,
                state_name:v_data.state_name,
                state: v_data.state_id,
                city_name:v_data.city_name,
                city: v_data.city_id,
                email: v_data.email,
                address: v_data.address,
                plan_name:v_data.plan_name,
                membershipType: v_data.plan_id,
                membershipStartDate:new Date(v_data.start_date).toISOString().split('T')[0],
                membershipEndDate: new Date(v_data.end_date).toISOString().split('T')[0],
                bankName: v_data.bank_name ,
                bankAccountNumber: v_data.bank_account_number,
                bankIFSC: v_data.IFSC,
                nameAsPerBank: v_data.name_on_bank,
                tradersLicenseNumber: v_data.tradeLicense_number,
                licenseExpDate: new Date(v_data.license_exp_date).toISOString().split('T')[0],
                serviceOffering: v_data.service_offerings,
            }
            setVendorData(d);
        } catch (error) {
            console.error("Failed to fetch services:", error);
        } finally {
            setIs_display(true);
        }
    }

 

    useEffect(() => {
        if(vendor_id != 0){
            getVendorDetails()
        }else{
            setIs_display(true)
        }
    }, []);

  return (

    <div className="container mt-4">
        <div className="card p-4 shadow-lg">
            <h1 className="heading">
                <span className="me-4 pointer">
                    <IoArrowBack size={24} onClick={() => navigate('/vendors')} />
                </span>
                {vendor_id == 0 ? "Add a Vendor" : "Edit Vendor"}
            </h1>
            <hr className='mb-4' />
            
            <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                    className={`d-flex align-items-center justify-content-center border rounded-circle primaryBackground text-white`}
                    style={{ width: '40px', height: '40px' }}>1
                </div>
                <div><hr style={{ border: "1px solid grey",width:"80px"}}/></div>
                <div
                    className={`d-flex align-items-center justify-content-center border rounded-circle 
                    ${step ==2 ? `primaryBackground text-white`:`bg-light text-dark`}`}
                    style={{ width: '40px', height: '40px' }}>2
                </div>
            </div>  
        
            {!is_display && (
                <Skeleton count={5} height={30} />
            )}

            {step === 1 && is_display &&(
                    <VendorForm1 
                        navigate={navigate}
                        vendorData={vendorData}
                        onSubmit={(data) => { setData(data) }} 
                    />
                )}

                {step === 2 && (
                    <VendorForm2 
                        vendorData={vendorData}
                        vendor_id={vendor_id}
                        prev={prevStep}
                        onSubmit={(data) => { save(data) }}
                        
                    />
                )}




</div>

    </div>
  );
};

export default VendortSignUp;