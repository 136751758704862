import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as apicall from '../../components/Apicall';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';
import { RiDeleteBin5Fill } from 'react-icons/ri';

const AdsForm = () => {
  const location = useLocation();
  const { id, adData } = location.state || {};
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputImageRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: adData?.name || '',
    description: adData?.description || '',
    redirect_url: adData?.redirect_url || '',
    image: adData?.image || '',
    startDate: adData?.start_date || '',
    endDate: adData?.end_date || '',
  });

  const IMAGE_SIZE_LIMIT = 500 * 1024;
  const IMAGE_DIMENSIONS_WIDTH = 720;
  const IMAGE_DIMENSIONS_HEIGHT = 720;


  const urlToBase64 = async (url) => {
     await fetch(url).then(response => response.blob()).then(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
      });
    });
  };

  useEffect(() => {
    if (adData) {
      setInitialValues({
        name: adData.name || '',
        description: adData.description || '',
        redirect_url: adData.redirect_url || '',
        image: adData.image || '',
        startDate: adData.start_date ? adData.start_date.split('T')[0] : '',
        endDate: adData.end_date ? adData.end_date.split('T')[0] : '',
      });
      urlToBase64(adData.image)
    }
  }, [adData]);

  const validationSchema = Yup.object({
    name: Yup.string().max(80, 'Name cannot exceed 80 characters').required('Name is required'),
    description: Yup.string().max(900, 'Description cannot exceed 900 characters'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date can’t be before Start Date'),
    redirect_url: Yup.string().max(200, 'URL cannot exceed 200 characters'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await handleSubmit(values, 1);
    },
  });

  const handleSubmit = async (values, isActive) => {
    const data = {
      id: id || 0,
      name: values.name,
      description: values.description,
      redirect_url: values.redirect_url,
      image: imagePreview,
      start_date: values.startDate,
      end_date: values.endDate,
      is_active: isActive,
    };

    try {
      const res = await apicall.post(data, 'ads');
      if (res.success) {
        toast.success(res.message);
        handleCancel();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message.replace('Error:', ''));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await handleSubmit(formik.values, 0);
    setShowDeleteModal(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImage = new Image();
      newImage.src = URL.createObjectURL(file);
      newImage.onload = function () {
        if (file.size > IMAGE_SIZE_LIMIT) {
          toast.error('Image size exceeds the limit of 500KB');
          return;
        }
        if (newImage.width <= IMAGE_DIMENSIONS_WIDTH && newImage.height <= IMAGE_DIMENSIONS_HEIGHT) {
          convertToBase64(file);
        } else {
          toast.error('Image dimensions are too large');
        }
      };
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setImagePreview(reader.result);
  };

  const handleCancel = () => navigate('/ads');

  return (
    <div className="container bg-white p-3 mt-4">
      <h3 className="heading">
        <span className="me-4 pointer">
          <IoArrowBack size={24} onClick={handleCancel} />
        </span>
        {id ? "Edit Ads" : "Add Ads"}
      </h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="name" className='label'>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={80}
              autoComplete="name"
            />
            {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
          </div>
          <div className="col-md-6">
            <label htmlFor="redirect_url" className='label'>Redirect URL</label>
            <input
              type="text"
              id="redirect_url"
              name="redirect_url"
              className={`form-control ${formik.touched.redirect_url && formik.errors.redirect_url ? 'is-invalid' : ''}`}
              value={formik.values.redirect_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={200}
            />
            {formik.touched.redirect_url && formik.errors.redirect_url && <div className="invalid-feedback">{formik.errors.redirect_url}</div>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <label htmlFor="description" className='label'>Description</label>
            <textarea
              id="description"
              name="description"
              className={`form-control ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
              rows="4"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={900}
            />
            {formik.touched.description && formik.errors.description && <div className="invalid-feedback">{formik.errors.description}</div>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className='label'>Start Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.startDate && formik.errors.startDate && <div className="invalid-feedback">{formik.errors.startDate}</div>}
          </div>
          <div className="col-md-4">
            <label htmlFor="endDate" className='label'>End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className={`form-control ${formik.touched.endDate && formik.errors.endDate ? 'is-invalid' : ''}`}
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.endDate && formik.errors.endDate && <div className="invalid-feedback">{formik.errors.endDate}</div>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12 mt-4 d-flex align-items-center">
            <label htmlFor="upload-image" className="label mt-2">Upload an Image:</label>
            {imagePreview ? (
              <div className="d-flex align-items-center mx-3">
                <img src={imagePreview} alt="Uploaded"  style={{height:'150px', width:'200px'}}/>
                <button
                  type="button"
                  className="btn btn-outline-secondary ms-3 mt-1"
                  onClick={() => {
                    setImagePreview(null);
                    formik.setFieldValue('image', '');
                  }}
                >
                  Remove
                </button>
              </div>
            ) : (
              <>
                <button type="button" className="btn btn-outline-secondary ms-3 mt-1" onClick={() => fileInputImageRef.current.click()}>
                  Upload
                </button>
                <input type="file" ref={fileInputImageRef} id="upload-image" name="image" className="d-none" onChange={handleImageChange} />
              </>
            )}
          </div>

          <div className='mt-3 '>
              <label className=" img-info   ">
                Max Size: {IMAGE_SIZE_LIMIT / 1024} KB<br />
                Dimensions: {IMAGE_DIMENSIONS_WIDTH}w x {IMAGE_DIMENSIONS_HEIGHT} h
              </label>
            </div>
        </div>

        {id !== 0 && id && (
          <div className="mt-5">
            <p className="content mb-1">Delete this ad</p>
            <p className="label mb-1">This ad will be deleted.</p>
            <button
              type="button"
              className="btn btn-outline-danger button mt-3"
              onClick={() => setShowDeleteModal(true)}
            >
              <RiDeleteBin5Fill className="me-2" />
              Delete
            </button>
          </div>
        )}

        <div className="d-flex justify-content-center mt-5 mb-4">
          <button
            type="button"
            className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button type="submit" className="btn Button primaryBackground text-center text-white position-relative d-flex justify-content-center align-items-center">
            {loading ? <Loader style={{ height: "30", width: "40" }} /> : 'Save'}
          </button>
        </div>
      </form>



      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered size='xxl'>

        <div className="d-flex flex-column justify-content-center align-items-center p-1 m-5">
          <p className="text-center mb-3">
            Are you sure? <br />
            You want to delete this Ads?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
              onClick={handleDelete}
            >
              {loading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>

      </Modal>

      <ToastContainer />
    </div>
  );
};

export default AdsForm;