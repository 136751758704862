import React from 'react'
import Table from '../../components/Table';

const VendorServiceHistory = () => {

    const serviceHeaders = ['Service Type', 'Selected Offerings','Booking Dtae','UserName', 'Amount', 'Status'];
    const serviceData = [
      ['Packers& movers','1 BHK', '14 Aug 2024', 'Lydia Passaquindici', '₹40','InProcess' ],
      ['  Packers & Movers', '2 BHK','14 Aug 2024',' Passaquindici', '₹60','Completed'],
    ];
  
    
  return (
    <div>
      
        
      <div className="row mb-3 secondary">
        <h1 className="sub-heading mt-3">Service History</h1>

        <Table headers={serviceHeaders} data={serviceData} />
      </div>
    </div>
  )
}

export default VendorServiceHistory