import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { Form, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

const Enduser = () => {
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [searchKey, setSearchKey] = useState('');
    const navigate = useNavigate();

    const userHeaders = ['Name', 'Phone Number', 'Email', 'Emirate', 'Is_active'];

    useEffect(() => {
        if (searchKey == "") {
            getUsersList();
        }
    }, [searchKey]);

    const getUsersList = async (searchKey = '') => {
        setLoadingUsers(true);
        try {
            const res = await apicall.post({ searchKey }, '/get_all_endUsers');
            setUsers(res.data || []);
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching users.');
        } finally {
            setLoadingUsers(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getUsersList(searchKey);
    };
    const handleEdit = (index) => {
        const user = users[index];
        navigate(`/userDetails/${user.id}`, { state: { user } });
    };

    

    const formattedUsers = users.map(({ first_name, middle_name, phone_number, email, state_name, is_active }) => [
        `${first_name} ${middle_name}`,
        phone_number,
        email,
        state_name,
        is_active === 1 ? 'Yes' : 'No'
    ]);

    return (
        <div className="container my-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div >
                    <h3 className="heading">End Users</h3>
                    <p className="content">Here is a list of all End Users</p>
                </div>

                <div className='d-flex justify-content-center flex-grow-1'>
                    <Form onSubmit={handleSearchSubmit} className="w-100">
                        <InputGroup className="justify-content-center">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                                style={{ maxWidth: '350px' }}
                            />
                            <InputGroup.Text className='primaryBackground' onClick={handleSearchSubmit}>
                                <FaSearch className='text-white' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </div>
            </div>
            {loadingUsers ? (
                <Skeleton height={30} count={5} className='mt-3' />
            ) : (
                <Table headers={userHeaders} data={formattedUsers} pagination={true} recordsCount={10} details={handleEdit} />
            )}
        </div>
    );
};

export default Enduser;