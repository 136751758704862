import React from 'react'

export const VendorBasicDetails = ({ vendorData ,vendor_id }) => {

  return (
    <div>

<div className="row mb-3 Secondary-Variant">
        <div className="col-12 p-3">
          <h1 className="sub-heading mb-3">Basic Details</h1>
          <div className="row g-3">
            <div className="col-md-4 text-start">
              <p className="label mb-1">Vendor Name</p>
              <p className="content mb-1">{vendorData.vendor_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Service Type</p>
              <p className="content mb-1">{vendorData.service_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Contact</p>
              <p className="content mb-1">{vendorData.phone_number}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Country</p>
              <p className="content mb-1">{vendorData.country_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Emirate</p>
              <p className="content mb-1">{vendorData.state_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">City</p>
              <p className="content mb-1">{vendorData.city_name}</p>
            </div>
            <div className="col-12 text-start">
              <p className="label mb-1">Address</p>
              <p className="content mb-1">
              {vendorData.address}
              </p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Membership Type</p>
              <p className="content mb-1">{vendorData.plan_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Start Date</p>
              <p className="content mb-1">{vendorData.start_date}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">End Date</p>
              <p className="content mb-1">{vendorData.end_date}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Status</p>
              <p className="content mb-1">{vendorData.is_active ? "Active":"Not Active"}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Trader License Number</p>
              <p className="content mb-1">{vendorData.tradeLicense_number}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">License Exp. Date</p>
              <p className="content mb-1">{vendorData.license_exp_date}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
           <div className="row mb-3 Secondary-Variant">
        <div className="col-12 p-3">
          <h1 className="sub-heading">Bank Details</h1>
          <div className="row g-3">
            <div className="col-md-4 text-start">
              <p className="label mb-1">Bank Name</p>
              <p className="content mb-1">{vendorData.bank_name}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">Account Number</p>
              <p className="content mb-1">{vendorData.bank_account_number}</p>
            </div>
            <div className="col-md-4 text-start">
              <p className="label mb-1">IFSC</p>
              <p className="content mb-1">{vendorData.IFSC}</p>
            </div>
            <div className="col-12 text-start">
              <p className="label mb-1">Name as per the bank account</p>
              <p className="content mb-1">{vendorData.name_on_bank}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    </div>
  )
}