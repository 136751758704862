import { Button } from "react-bootstrap";
import { useNavigate, NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();

  const isStaffActive = ['/staff', '/staffForm', '/staffDetails'].some(path => location.pathname.startsWith(path));
  const isEndUserActive = ['/user', '/userDetails'].some(path => location.pathname.startsWith(path));
  const isVendorActive = ['/vendor', '/vendorDetails'].some(path => location.pathname.startsWith(path));
  const isMasterDataActive = ['country', 'state', 'city', 'memberShip'].some(path => 
    location.pathname.includes(path) );
  const isServiceActive = ['/service_offerings', '/service_offerings_form'].some(path =>
    location.pathname.startsWith(path) );
  const isInquiriesActive = location.pathname.startsWith('/inquiey');
  const isCouponsActive = ['/coupon/0', '/couponDetails'].some(path => location.pathname.startsWith(path));
  const isAdsActive = location.pathname.startsWith('/adsform');

  const navigate = useNavigate();

  const logout = async () => {
    try {
      localStorage.clear();
      navigate('/login');
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="" href="/services">
          <img src="/image/navbar_logo.png" alt="Logo" className="navbar-logo" />
        </a>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <NavLink to="services" className={({ isActive }) => (isActive || isServiceActive ? 'nav-link active text-primary' : 'nav-link')}>
              Services
            </NavLink>

            <li className={`nav-item dropdown ${(isStaffActive || isEndUserActive || isVendorActive) ? 'active text-primary' : ''}`}>
              <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Users
              </div>
              <ul className="dropdown-menu">
                <NavLink to="staff" className={() => (isStaffActive ? 'dropdown-item active text-primary' : 'dropdown-item ')}>
                  <li>Staff</li>
                </NavLink>
                <NavLink to="user" className={() => (isEndUserActive ? 'dropdown-item active text-primary' : 'dropdown-item ')}>
                  <li>End User</li>
                </NavLink>
                <NavLink to="vendors" className={() => (isVendorActive ? 'dropdown-item active text-primary' : 'dropdown-item')}>
                  <li>Vendor</li>
                </NavLink>
              </ul>
            </li>

            <li className={`nav-item dropdown ${isMasterDataActive ? 'active text-primary' : ''}`}>
              <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Master Data
              </div>
              <ul className="dropdown-menu">
                <NavLink to="country" className={({ isActive }) => (isActive ? 'dropdown-item active text-primary' : 'dropdown-item')}>
                  Country
                </NavLink>
                <NavLink to="state" className={({ isActive }) => (isActive ? 'dropdown-item active text-primary' : 'dropdown-item')}>
                  State
                </NavLink>
                <NavLink to="city" className={({ isActive }) => (isActive ? 'dropdown-item active text-primary' : 'dropdown-item')}>
                  City
                </NavLink>
                <NavLink to="memberShip" className={({ isActive }) => (isActive ? 'dropdown-item active text-primary' : 'dropdown-item')}>
                  Membership Plans
                </NavLink>
              </ul>
            </li>

            <NavLink to="inquiries" className={({ isActive }) => (isActive || isInquiriesActive ? 'nav-link active text-primary' : 'nav-link')}>
              Inquiries
            </NavLink>

            <NavLink to="coupons" className={({ isActive }) => (isActive || isCouponsActive ? 'nav-link active text-primary' : 'nav-link')}>
              Coupons
            </NavLink>

            <NavLink to="ads" className={({ isActive }) => (isActive || isAdsActive ? 'nav-link active text-primary' : 'nav-link')}>
              Ads
            </NavLink>

            <NavLink to="bookings" className={({ isActive }) => (isActive ? 'nav-link active text-primary' : 'nav-link')}>
          Bookings
            </NavLink>

            <NavLink to="chat" className={({ isActive }) => (isActive ? 'nav-link active text-primary' : 'nav-link')}>
              Chat
            </NavLink>
          </ul>

          <Button className="btn primaryBackground text-white m-1 border-0" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;