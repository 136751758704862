import React, { useState, useEffect, useRef } from 'react';
import { ListGroup, Form, Button, InputGroup } from 'react-bootstrap';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';

var pubnub = new PubNub({
  publishKey: "pub-c-9020aa61-fd49-42aa-888e-da5f47448738",
  subscribeKey: "sub-c-e81838e0-9a1a-4c04-9c3f-52f953fc417f",
  uuid: "us_admin",
});

const ChatApp = () => {

  const scrollRef = useRef(null);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeUser, setActiveUser] = useState();
  const activeUserRef = useRef(activeUser);
  const [userMessages, setUserMessages] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const colors = ["primary", "secondary", "success", "danger", "warning", "info", "dark"]
  //const pubnub = usePubNub();



  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
  }, [users]);

  // useEffect(() => {
  //   activeUserRef.current = activeUser;
  //   fetchHistory(activeUser);
  // }, [activeUser]);


 // Scroll to the latest message whenever a new message is added

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
     fetchHistory(activeUser);
  }, [activeUser?.messages]);


  

  useEffect(() => {
    var filteredData = users?.filter((user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredUsers(filteredData)
  }, [searchTerm]);

  const getColor = () => {
    return colors[Math.floor(Math.random() * 6)];
  }

  const timeFormate = (time) => {
    const timestampInMs = time / 10000;
    const messageDate = new Date(timestampInMs);
    var formated_time = messageDate.toLocaleString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
    formated_time = formated_time.replace(" at ", " ");
    return formated_time;
  }

  const getUsersList = async () => {
    setLoading(true);
    try {
      // call API
      let res = await apicall.get("admin_chatInfo");

      /*pubnub = new PubNub({
        publishKey: res.pubnub.pubnub_publish_key,
        subscribeKey: res.pubnub.pubnub_subscribe_key,
        uuid: res.pubnub.pubnub_uuid,
      }); */

      // subscribe to pubnub
      var channels_list_aray = [];
      for (const user of res.data) {
        channels_list_aray.push(user.pubnub_channel_id);
      }
      connectPubNub(channels_list_aray)

      var usersList = [];
      for (const user of res.data) {
        var messages = [];
        // fectch initial message of user
        const msg_response = await pubnub.fetchMessages({
          channels: [user.pubnub_channel_id],
          includeMessageActions: true,
          count: 1
        });
        var message = msg_response.channels[user.pubnub_channel_id]
        var startTimeToken = "";
        var messageCount = 0;
        if (message) {
          var m = {
            "sender": message[0].uuid === user.pubnub_uuid ? 1 : 2, // 1 - user message 2 - admin reply
            "message": message[0].message.description,
            "time": timeFormate(message[0].timetoken),
            "timetoken": message[0].timetoken,
          }
          messages.push(m)
          startTimeToken = message[0].timetoken;
          messageCount = 1;
        }

        var obj = {
          "id": user.id,
          "pubnub_uuid": user.pubnub_uuid,
          "pubnub_channel_id": user.pubnub_channel_id,
          "name": user.first_name,
          "initial": user.initial,
          "color": getColor(),
          "messages": messages,
          "startTimeToken": startTimeToken,
          "messageCount": messageCount
        }
        usersList.push(obj);
      }
      const sortedList = [...usersList];
      sortedList.sort((a, b) => a.startTimeToken > b.startTimeToken ? -1 : 1);

      setUsers(sortedList);
      setFilteredUsers(sortedList);
      setActiveUser(sortedList[0]);
      setLoading(false);
    } catch (e) {
      toast.error(e.message.replace('Error:', ''))
      setLoading(false);
    }
  }

  const updateUserObject = async (messageData, userData) => {
    debugger
    if (messageData != undefined && messageData.length > 0) {

      var messageList = [];
      for (const msg of messageData) {
        var m = {
          "sender": msg.uuid === userData.pubnub_uuid ? 1 : 2, // 1 - user message 2 - admin reply
          "message": msg.message.description,
          "time": timeFormate(msg.timetoken),
          "timetoken": msg.timetoken,
        }
        messageList.push(m);
      }

      messageList.push(...userData.messages);

      var temp_userList = users;
      var index = temp_userList?.findIndex(x => x.id === userData.id);
      if (index != -1) {
        temp_userList[index].messages = messageList;
        //temp_userList[index].startTimeToken = messageData[messageData.length - 1].timetoken
        temp_userList[index].startTimeToken = messageData[0].timetoken
        temp_userList[index].messageCount = userData.messageCount + messageData.length;

        setUsers([...temp_userList]);
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        //setFilteredUsers(temp_userList);
      }

    }
  }

  const fetchHistory = async (userData) => {
    try {
      if (userData.messageCount != 0) {
        pubnub.fetchMessages(
          {
            channels: [userData.pubnub_channel_id],
            stringifiedTimeToken: true,
            includeMeta: true,
            includeMessageActions: true,
            count:100,
            //includeMessageType: boolean,
            //includeUUID: boolean,
            //includeMeta: boolean,
            start: userData.startTimeToken,
            //end: string
          },
          function (status, response) {
            if (!status.error) {
              updateUserObject(response.channels[userData.pubnub_channel_id], userData)
            } else {
              // alert("Can't fetch your chat History")
            }
          }
        );
      }
    } catch (error) {
      console.error('Error fetching message history:', error);
    }
  };

  const updateNewMessage = async (messageData) => {
    debugger
    var userList = users;
    var i = userList?.findIndex(x => x.pubnub_uuid === messageData.publisher);
    var m = {
      "sender": i != -1 ? 2 : 1, // 1 - user message 2 - admin reply
      "message": messageData.message.description,
      "time": timeFormate(messageData.timetoken),
      "timetoken": messageData.timetoken,
    }
    var index = userList?.findIndex(x => x.pubnub_channel_id === messageData.channel);
    if (index != -1) {
      userList[index].messages.append(m);
      setUsers([...userList]);
      // scrollRef.current?.scrollIntoView({behavior: 'smooth'});
    }

  }

  const connectPubNub = async (channels) => {
    debugger

    pubnub.subscribe({ channels: channels });

    const messageListener = {
      message: (event) => {
        console.log("event = ", event);
        updateNewMessage(event);
        /* const { message, channel, publisher } = event;
         const newMessage = {
           id: message.id || new Date().getTime(),
           sender: publisher,
           text: message.text,
           time: new Date().toLocaleTimeString(),
           type: publisher === pubnub.getUUID() ? 'sent' : 'received',
         };
 
         setUserMessages((prevMessages) => {
           const currentMessages = prevMessages[channel] || [];
           const isDuplicate = currentMessages.some((msg) => msg.id === newMessage.id);
           if (isDuplicate) return prevMessages;
 
           return {
             ...prevMessages,
             [channel]: [...currentMessages, newMessage],
           };
         }); */
      },
    };

    pubnub.addListener(messageListener);

    return () => {
      pubnub.unsubscribeAll();
      pubnub.removeListener(messageListener);
    };
  }



  const publishMessage = async () => {
    if (newMessage.trim() === '') return;
    const publishPayload = {
      channel: activeUser.pubnub_channel_id,
      message: {
        //title: "greeting",
        description: newMessage
      }
    };
    try {
      await pubnub.publish(publishPayload);
      setNewMessage("");
    } catch (e) {
      alert("error");
    }
  };

  const handleUserClick = (user) => {
    setActiveUser(user);
  };

  return (
    <div>
      {!loading &&
        <PubNubProvider client={pubnub}>
          <div className="d-flex " >
            <div className="col-3 border-end p-3  bg-white" style={{ overflowY: 'auto', height: '88vh' }}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Search for users"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <ListGroup variant="flush">
                {filteredUsers.map((user) => (
                  <ListGroup.Item
                    key={user.id}
                    action
                    onClick={() => handleUserClick(user)}
                    active={user.id === activeUser.id}
                    className="user-item m-1"
                    style={{ backgroundColor: user.id === activeUser.id ? '#ffe4e4' : '', color: 'black' }}
                  >
                    <div className="d-flex align-items-center ">
                      <div
                        className={`bg-${user.color} text-white d-flex align-items-center justify-content-center`}
                        style={{ width: 40, height: 40, borderRadius: '5px', marginBottom: '11px' }}
                      >
                        <p className='mt-3'> {user.initial}</p>
                      </div>

                      <div className="ms-2 " style={{ marginTop: '5px' }}>
                        <p className="mb-0 small">{user.name}</p>
                        <p className="text-muted label ">{user.messages[0]?.message}</p>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

            </div>

            <div className="col-9 d-flex flex-column bg-white">
              <div className="border-bottom p-3 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{activeUser?.name}</h5>
              </div>
              {activeUser?.messages &&
                <div className="flex-grow-1 p-3 overflow-auto" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                  <div className=' d-flex justify-content-center align-items-center label m-2'   onClick={() => alert('This is an older message')} > see older message</div>
                  {(activeUser?.messages).map((message) => (
                    <div
                      key={message.timetoken}
                      className={`d-flex flex-column align-items-${message.sender === 2 ? 'end' : 'start'} mb-3`}
                    >
                      <div
                        className={`p-2 w-50 ${message.sender === 2 ? 'primaryBackground  text-white' : 'bg-light'}`}
                        style={{ wordWrap:'break-word',borderRadius:'15px 15px 0px 15px' }}
                      >
                        <p className="mb-0 fw-bold  primaryColorText ">{activeUser?.name}</p>
                        <p className="mb-0">{message.message}</p>
                        <div className="d-flex justify-content-between">
                          <span></span>
                          <p className="mb-0 small d-flex justify-content-between">{message.time}</p>
                        </div>
                      </div>

                    </div>
                  ))}
                  <div ref={scrollRef}></div>
                </div>
              }

              <div className="border-top p-3">
                <InputGroup>
                  <Form.Control
                    placeholder="Write your message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  {<Button className='primaryBackground border-0 ' onClick={publishMessage}>
                    ➤
                  </Button>}
                </InputGroup>
              </div>
            </div>
          </div>
        </PubNubProvider>
      }
    </div>
  );
};



export default ChatApp;