import React from 'react';
import { useNavigate, useLocation,Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const authenticate = localStorage.getItem('token') ? true : false;
  return authenticate ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;