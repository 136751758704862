import React, { useEffect, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as apicall from '../../components/Apicall';
import Skeleton from '../../components/SekeltonLoder';
import Loader from '../../components/Loader';

const CouponDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { couponId } = location.state || {};
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    const fetchCouponDetails = async () => {
      if (couponId) {
        try {
          const response = await apicall.get(`coupon/${couponId}`);
          setCoupon(response.data[0]);
        } catch (error) {
          console.error('Error fetching coupon details:', error);
          toast.error('Failed to load coupon details. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCouponDetails();
  }, [couponId]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDeleteCoupon = async () => {
    setDeleting(true);
    try {
      await apicall.get(`/delete_coupon/${couponId}`);
      toast.success('Coupon deleted successfully');
      navigate('/coupons');
    } catch (error) {
      console.error('Error deleting coupon:', error);
      toast.error('Failed to delete coupon. Please try again.');
    } finally {
      setDeleting(false);
      setShowModal(false);
    }
  };

  return (
    <div className="container my-4 p-3 shadow rounded-3">
      <div className="mb-2 d-flex align-items-center pointer">
        <IoArrowBack size={24} onClick={() => navigate('/coupons')} className="me-2" />
        <h1 className="sub-heading mb-0">Coupon Details</h1>
      </div>


      <div className="row mb-3 Secondary-Variant">
        <div className="col-12 p-4">
          <div className="row g-3">
            <div className="col-4 mt-3 mb-2">
              <p className="label mb-1">Coupon Code</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? coupon.code : 'N/A'}</p>
              )}
            </div>

            <div className="col-4 mb-2">
              <p className="label mb-1">% Off</p>
              {loading ? (
                <Skeleton />
              ) : (

                <p className="content mb-1">{coupon ? `${coupon.value}%` : 'N/A'}</p>
              )}
            </div>

            <div className="col-4 mb-2">
              <p className="label mb-1">Redeemed Count</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? coupon.redeemed_count : 'N/A'}</p>
              )}
            </div>
          </div>

          <div className="row g-3 mb-2 mt-2">
            <div className="col-4 mb-2">
              <p className="label mb-1">Valid From</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? new Date(coupon.start_date).toLocaleDateString() : 'N/A'}</p>
              )}
            </div>

            <div className="col-4 mb-2">
              <p className="label mb-1">Valid Until</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? new Date(coupon.end_date).toLocaleDateString() : 'N/A'}</p>
              )}
            </div>

            <div className="col-4 mb-2">
              <p className="label mb-1">Is Public:</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon.app_display === 1 ? "Yes" : "No"}</p>
              )}
            </div>
          </div>

          <div className="row g-3 mb-2 mt-2">
            <div className="col-6 mb-2">
              <p className="label mb-1">Description</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? coupon.description : 'N/A'}</p>
              )}
            </div>


          </div>

          <div className='row g-3 mb-2 mt-2'>
            <div className="col-4 mb-2">
              <p className="label mb-1">Type of Service</p>
              {loading ? (
                <Skeleton />
              ) : (
                <p className="content mb-1">{coupon ? coupon.services : 'N/A'}</p>
              )}
            </div>

          </div>

          <div className="row g-3">
            <div className="col-4 mb-2">
              <p className="label mb-1">Uploaded Image</p>
              {coupon && (
                <img
                  src={coupon.image}
                  alt="Uploaded"
                  className="img-fluid rounded"
                  style={{ width: '200px', height: '150px' }}
                />
              )}
            </div>

            {role == 1 &&
              <div className="col-6 mb-2">
                <div className="mb-3">
                  <p className="content mb-1">Delete this coupon</p>
                  <p className="label mb-1">This coupon will be deleted.</p>
                  <button className="btn btn-outline-danger button mt-3" onClick={handleShowModal}>
                    <RiDeleteBin5Fill className="me-2" />
                    Delete
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered size="xxl">
        <div className="d-flex flex-column justify-content-center align-items-center p-1 m-3">
          <p>Are you sure you want to delete this coupon?</p>

          <div className="d-flex justify-content-center align-items-center mb-4 mt-4">
            <button
              className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
              onClick={handleDeleteCoupon}
              disabled={deleting}
            >
              {deleting ? <Loader style={{ height: "30", width: "40" }} /> : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default CouponDetails;