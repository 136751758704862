import React from 'react';

const steps = [
  { label: 'Booked' },
  { label: 'Vendor Confirmed' },
  { label: 'Truck Assigned' },
  { label: 'Pick Up' },
  { label: 'Delivered' }
];

const BookingsIndictor = ({ currentStep }) => {
  return (
    <div className="container my-4">

      <div className="position-relative mb-5" style={{ height: '40px' }}>
        <div className="position-absolute top-50 start-0 w-100 translate-middle-y">
          <div className="progress" style={{ height: '5px' }}>
            <div
              className="progress-bar primaryBackground"
              role="progressbar"
              style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
              aria-valuenow={((currentStep + 1) / steps.length) * 100}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>

        <div className="row d-flex justify-content-between position-absolute top-50 start-0 w-100 translate-middle-y">
          {steps.map((step, index) => (
            <div key={index} className="col text-center">
              <div
                className={`rounded-circle ${
                  index < currentStep ? 'primaryBackground text-white' : index === currentStep ? 'primaryBackground text-white' : 'bg-light text-muted'
                } d-flex justify-content-center align-items-center mx-auto`}
                style={{ width: '30px', height: '30px' }}
              >
                {index < currentStep ? '✓' : index === currentStep ? '✓' : ''}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        {steps.map((step, index) => (
          <div key={index} className="col text-center">
            <small className={index <= currentStep ? 'text-danger' : 'text-muted'}>
              {step.label}
            </small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingsIndictor;