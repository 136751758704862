import { ThreeDots } from "react-loader-spinner";
const Loader = ({style}) => {
    return (
        <div>
           <ThreeDots
                height= {style.height ?  style.height : "40"}
                width={style.width ?  style.width : "80"}
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default Loader;