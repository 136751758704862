import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';

const VendorFeedback = ({vendor_id,vendor_ratings}) => {
  const itemsPerPage = 5; 
  const [feedback, setFeedback] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const colors = ["primary", "secondary", "success", "danger", "warning", "info", "dark"]

  const getVendorFeedBack = async () => {
    try {
      const res = await apicall.get(`vendorFeedBacklist/${vendor_id}`);
      console.log("res = ",res)
      setFeedback(res.data);
    } catch (error) {
      console.error('Failed to fetch vendor details:', error);
      toast.error('Failed to load vendor details.');
    }
  }; 

  const getColor = () => {
    return colors[Math.floor(Math.random() * 6)];
  }

  useEffect(() => {
    getVendorFeedBack();
  }, []);

  const totalPages = Math.ceil(feedback.length / itemsPerPage);

  const currentItems = feedback.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div className="row secondary">
        <div className="col-12 Secondary-Variant py-4">
          <h1 className="sub-heading mt-2 mb-3">Customer’s Feedback</h1>
        <h1 className="label mt-2">Rating</h1>
          <div className="d-flex">
            <p className="content mt-2">{vendor_ratings}</p>
            <p className="ms-2 mt-2">{"⭐".repeat(vendor_ratings)}</p>
          </div>
        </div>
      </div>

      {currentItems.map((item) => (
        <div key={item.id} className="row mt-3 align-items-start">
          <div className="col-auto">
            <div className={`bg-${getColor()} p-2 text-white rounded d-flex align-items-center justify-content-center`}>
              {item.user_name.charAt(0)}
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <div>
                <h1 className="mb-1 content">{item.user_name}</h1>
                <p className="label mb-1">Service: {item.service_name}</p>
              </div>
              {/*<div className="text-end label">{item.timeAgo}</div>*/}
            </div>
          </div>
          <div className="mt-2 border-bottom">
            <div className="d-flex align-items-center">
              <span className="content text-start">{item.ratings}</span>
              <span className="text-warning mx-2">{"⭐".repeat(item.ratings)}</span>
            </div>
            <p className="mt-2 content">{item.review}</p>
          </div>
        </div>
      ))}

      {totalPages > 1 && (
        <nav
          aria-label="Page navigation example"
          className="d-flex justify-content-center mt-3 flex-wrap"
          style={{ wordWrap: 'break-word' }}
        >
          <ul
            className="pagination d-flex flex-wrap justify-content-center overflow-auto"
            style={{ maxWidth: '100%' }}
          >

            <li className="page-item">
              <button
                className="btn primaryColorText btn-outline-secondary text-black m-2 Button"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {currentPage > 3 && (
              <li className="page-item disabled">
                <button className="btn primaryColorText btn-outline-secondary m-2 p-2 btn-width">
                  ...
                </button>
              </li>
            )}

            <li className="page-item">
              <button
                className={`btn primaryColorText btn-outline-secondary m-2 p-2 btn-width ${
                  currentPage === 1 ? 'active' : ''
                }`}
                onClick={() => paginate(1)}
              >
                1
              </button>
            </li>
            {Array.from({ length: 3 }, (_, i) => currentPage - 1 + i)
              .filter((pageNumber) => pageNumber > 1 && pageNumber < totalPages)
              .map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`page-item ${
                    currentPage === pageNumber ? 'active' : ''
                  }`}
                >
                  <button
                    className="btn primaryColorText btn-outline-secondary m-2 p-2 btn-width"
                    onClick={() => paginate(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

            {/* Ellipsis for pages beyond */}
            {currentPage < totalPages - 2 && (
              <li className="page-item disabled">
                <button className="btn primaryColorText btn-outline-secondary m-2 p-2 btn-width">
                  ...
                </button>
              </li>
            )}

            <li className="page-item">
              <button
                className={`btn primaryColorText btn-outline-secondary m-2 p-2 btn-width ${
                  currentPage === totalPages ? 'active' : ''
                }`}
                onClick={() => paginate(totalPages)}
              >
                {totalPages}
              </button>
            </li>

            <li className="page-item">
              <button
                className="btn primaryColorText btn-outline-secondary text-black m-2 Button"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default VendorFeedback;