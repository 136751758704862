import { PiMaskSad } from "react-icons/pi";
const NoPage = () =>{
    return (
        <div class="pt-5">
             <div class="text-center pt-5">
                <PiMaskSad size="10%" color="gray"/>
                <h1 class="pt-3 greyColor">404</h1>
                <p class="greyColor">The page you are looking fordoesn't exist or an other error occurred.</p>
                <p class="greyColor"><a class="greyColor" href="/dashboard">Click here</a> to go back.</p>
             </div>
        </div>
       
    )
}

export default NoPage;