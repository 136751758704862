import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as apicall from '../../components/Apicall';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const ServiceOfferingsForm = () => {
  const { offerings_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceName, offering, mode, service_id } = location.state || {};
  const [iconPreview, setIconPreview] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const fileInputIconRef = useRef(null);
  const fileInputImageRef = useRef(null);

  const ICON_SIZE_LIMIT = 100 * 1024;
  const IMAGE_SIZE_LIMIT = 500 * 1024;
  const ICON_DIMENSIONS_WIDTH = 260;
  const ICON_DIMENSIONS_HEIGHT = 260;
  const IMAGE_DIMENSIONS_WIDTH = 720;
  const IMAGE_DIMENSIONS_HEIGHT = 720;

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(40, 'Max 40 characters allowed')
      .required('Name is required'),
    shortDescription: Yup.string()
      .max(90, 'Max 90 characters allowed')
      .required('Short Description is required'),
    description: Yup.string()
      .max(1900, 'Max 1900 characters allowed'),
  });

  const formik = useFormik({
    initialValues: {
      name: offering?.name || '',
      shortDescription: offering?.short_description || '',
      description: offering?.description != 'null' ? offering?.description : '',
      icon: iconPreview,
      image: imagePreview,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!iconPreview) {
        toast.error('Please upload an icon');
        return;
      }
      if (values.description === "" && !imagePreview) {
        toast.error('Please upload an image');
        return;
      }

      setLoading(true);

      try {
        const data = {
          id: offerings_id,
          name: values.name,
          short_description: values.shortDescription,
          description: values.description,
          icon: iconPreview,
          image: imagePreview,
          service_id: service_id,
        };
        var res = await apicall.post(data, 'service_offerings');
        if (res.success) {
          toast.success(res.message);
          handleCancel();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message.replace('Error:', ''))
      } finally {
        setLoading(false);
      }
    },
  });

  const getInputClass = (field) => {
    return formik.touched[field] && formik.errors[field]
      ? 'form-control is-invalid'
      : 'form-control';
  };

  const getPlaceholder = (field) => {
    return formik.errors[field] || '';
  };

  const handleIconUpload = () => {
    fileInputIconRef.current?.click();
  };

  const handleImageUpload = () => {
    fileInputImageRef.current?.click();
  };

  const convertToBase64 = (file, type) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type === "icon") {
        setIconPreview(reader.result);
      } else {
        setImagePreview(reader.result);
      }
    };
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > ICON_SIZE_LIMIT) {
        toast.error('Icon file size is too large');
        return;
      }
      let newImage = new Image();
      newImage.src = URL.createObjectURL(file);
      newImage.onload = () => {
        let w = newImage.width;
        let h = newImage.height;
        if (w <= ICON_DIMENSIONS_WIDTH && h <= ICON_DIMENSIONS_HEIGHT) {
          convertToBase64(file, "icon");
        } else {
          toast.error('Icon file is too large');
        }
      };
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        toast.error('Image file is too large');
        return;
      }
      let newImage = new Image();
      newImage.src = URL.createObjectURL(file);
      newImage.onload = () => {
        let w = newImage.width;
        let h = newImage.height;
        if (w <= IMAGE_DIMENSIONS_WIDTH && h <= IMAGE_DIMENSIONS_HEIGHT) {
          convertToBase64(file, "image");
        } else {
          toast.error('Image file is too large');
        }
      };
    }
  };

  const handleCancel = () => {
    navigate(`/service_offerings/${service_id}`, { state: { "service_name": serviceName } });
  };

  const urlToBase64 = async (url, type) => {
    /*const base64 = await fetch(url).then(response => response.blob()).then(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          if (type === "icon") {
            setIconPreview(reader.result);
          } else {
            setImagePreview(reader.result);
          }
        };
      });
    }); */

    if (type === "icon") {
      setIconPreview(url);
    } else {
      setImagePreview(url);
    }

  };

  useEffect(() => {
    if (offering?.icon) {
      urlToBase64(offering.icon, "icon");
    }
    if (offering?.description_image) {
      urlToBase64(offering.description_image, "image");
    }
  }, []);

  return (
    <div className="container bg-white p-3 mt-4">
      <h3 className="heading">
        <span className="me-4 pointer"><IoArrowBack size={24} onClick={handleCancel} /></span>
        {mode === 'edit' ? 'Edit ' : 'Add '}Service Offering for
        <span className="primaryColorText"> {serviceName}</span>
      </h3>

      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3 mt-4">
          <div className="col-md-4">
            <label htmlFor="name" className="label">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className={getInputClass('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxlength="40"
              placeholder={getPlaceholder('name')}
            />
            <div className="invalid-feedback">{formik.errors.name}</div>
          </div>
          <div className="col-md-8">
            <label htmlFor="shortDescription" className="label">Short Description</label>
            <input
              type="text"
              id="shortDescription"
              name="shortDescription"
              className={getInputClass('shortDescription')}
              value={formik.values.shortDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={getPlaceholder('shortDescription')}
              maxlength="40"
            />
            <div className="invalid-feedback">{formik.errors.shortDescription}</div>
          </div>
        </div>

        <div className="mb-3 mt-4">
  <label htmlFor="description" className="label">Description</label>
  <textarea
    id="description"
    name="description"
    className={getInputClass('description')}
    value={formik.values.description}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    placeholder={getPlaceholder('description')}
    rows={5} 
    maxlength="1900"
  />
  <div className="invalid-feedback">{formik.errors.description}</div>
</div>

<div className="form-group ">
  <div className="d-flex align-items-center">
    <div className="me-4">
      {iconPreview ? (
        <div className="d-flex align-items-center">
          <img
            src={iconPreview}
            alt="Uploaded Icon"
            className="img-fluid me-3 uploaded-image"
            height={60}
            width={60}
          />
          <button
            type="button"
            className="btn primaryColorText btn-outline-secondary ms-2"
            onClick={() => {
              setIconPreview(null);
              if (fileInputIconRef.current) {
                fileInputIconRef.current.value = '';
              }
            }}
          >
            Remove
          </button>
        </div>
      ) : (
        <>
          <label className="content mb-2">Upload an Icon:</label>
          <button
            type="button"
            className="btn primaryColorText btn-outline-secondary ms-3 mb-2 mt-2"
            onClick={handleIconUpload}
          >
            Upload
          </button>
          <input
            type="file"
            id="upload-icon"
            ref={fileInputIconRef}
            className="d-none"
            onChange={handleIconChange}
          />
        </>
      )}
      <div className="img-info">
        Max Size: {ICON_SIZE_LIMIT / 1024} KB<br />
        Dimensions: {ICON_DIMENSIONS_WIDTH}w x {ICON_DIMENSIONS_HEIGHT} h
      </div>
    </div>

    <div>
      {imagePreview ? (
        <div className="d-flex align-items-center ">
          <img
            src={imagePreview}
            alt="Uploaded Image"
            className="img-fluid me-3 uploaded-image mx-5"
            height={120}
            width={120}
          />
          <button
            type="button"
            className="btn primaryColorText btn-outline-secondary ms-2"
            onClick={() => {
              setImagePreview(null);
              if (fileInputImageRef.current) {
                fileInputImageRef.current.value = '';
              }
            }}
          >
            Remove
          </button>
        </div>
      ) : (
        <>
          <label className="content mb-2 mx-5">Upload an Image:</label>
          <button
            type="button"
            className="btn primaryColorText btn-outline-secondary ms-3 mb-2 mt-2"
            onClick={handleImageUpload}
          >
            Upload
          </button>
          <input
            type="file"
            id="upload-image"
            ref={fileInputImageRef}
            className="d-none"
            onChange={handleImageChange}
          />
        </>
      )}
      <div className="img-info mx-5">
        Max Size: {IMAGE_SIZE_LIMIT / 1024} KB<br />
        Dimensions: {IMAGE_DIMENSIONS_WIDTH}w x {IMAGE_DIMENSIONS_HEIGHT} h
      </div>
    </div>
  </div>
</div>


<div className='d-flex justify-content-center mt-5 mb-4'>
  <button
    type="button"
    className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect mt-4"
    onClick={handleCancel}
  >
    Cancel
  </button>
  <button
    type="submit"
    className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center mt-4"
  >
    {loading ? (
      <Loader style={{ height: "30", width: "40" }} />
    ) : (
      'Save'
    )}
  </button>
</div>
      </form>

      <ToastContainer />

    </div>
  );
};

export default ServiceOfferingsForm;