import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as apicall from '../../components/Apicall';
import {toast } from 'react-toastify';
import { useNavigate} from "react-router-dom";



function ServiceList() {

  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);


  const getServiceList = async () => {
    setLoading(true);
    try{
        let res = await apicall.get("services");
        setServices(res.data);
        setLoading(false);
    }catch(e){
        toast.error(e.message.replace('Error:',''))
        setLoading(false);
    }
  }

  useEffect(() => {
    getServiceList();
   }, []);

  return (
    <div className="container my-3">
      <div className="row">
        <div className="col-12 mb-3">
          <h1 className="heading fw-bold mt-2">Services</h1>
          <p className="content mb-3">Here is a list of all services</p>
        </div>
      </div>

      {loading ? (
        <div className="row g-4 mb-4">
          {Array(6).fill().map((_, index) => (
            <div key={index} className="col-md-4">
              <div className="card custom-card rounded-4">
                <div className="row g-0">
                  <div className="col-md-4 d-flex align-items-center p-3">
                    <Skeleton height={120} width={120} />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body mt-3">
                      <Skeleton height={24} width={150} />
                      <Skeleton height={16} count={3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (

        <div className="row g-4 mb-4">
          {services?.map(service => (
            <div key={service.id} className="col-md-4 pointer">
              <div className="card custom-card rounded-4" onClick={() => navigate('/service_offerings/'+service.id,{state: { "service_name":service.name }})}>
                <div className="row g-0">
                  <div className="col-md-4 d-flex align-items-center p-3">
                    <img src={service.image_url} className="img-fluid rounded-start" alt={service.name} />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body mt-4">
                      <h5 className="card-title subheading mt-2">{service.name}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ServiceList;