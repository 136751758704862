import React, { useEffect, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';
import Skeleton from '../../components/SekeltonLoder';
import BookingsIndictor from './BookingsIndictor';

const BookingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const {
    bookingId,
    searchTerm = '',
    statusFilter = null,
    serviceFilter = null,
    dateFilter = null,
    filterApplied = false,
  } = location.state || {};

  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentStep = 2;

  useEffect(() => {
    const fetchBookingDetails = async () => {
      if (bookingId) {
        try {
          const response = await apicall.get(`admin_bookingDetails/${bookingId}`);
          setBooking(response.data[0]);
        } catch (error) {
          console.error('Error fetching booking details:', error);
          toast.error('Failed to load booking details. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchBookingDetails();
  }, [bookingId]);

  const navigateBack = () => {
    navigate('/bookings', {
      state: {
        bookingId,
        searchTerm,
        statusFilter,
        serviceFilter,
        dateFilter,
        filterApplied,
      },
    });
  };

  return (
    <div className="container my-4 p-3 bg-white rounded-3">
      <div className="mb-2 d-flex align-items-center pointer">
        <IoArrowBack size={24} onClick={navigateBack} className="me-2" />
        <h1 className="sub-heading mb-0">Booking Details</h1>
      </div>


      <div className="row">
        <div className="col-md-6 mt-2">
          <div className='m-2 p-2'>
            <p className='sub-heading mx-4'>Vendor Details</p>
            <div className='row m-2 p-2'>
              <div className="col-md-6">
                <p className='label'>Allotted Vendor’s Name</p>
                <p className="content mb-1">{booking ? booking.vendor_name || '-' : <Skeleton />}</p>
                <p className='label'>Transaction ID</p>
                <p>{booking ? booking.transaction_id || '-' : <Skeleton />}</p>
                <p className='label'>Payment Status</p>
                <p className='content text-success'>{booking ? booking.payment_status || 'Pending' : <Skeleton />}</p>
              </div>
              <div className="col-md-6">
                <p className='label'>Vendor’s Contact</p>
                <p>{booking ? booking.vendor_contact || '-' : <Skeleton />}</p>
                <p className='label'>Amount</p>
                <p>{booking ? `₹${booking.vendor_payout_amount || '0.00'}` : <Skeleton />}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 vertical-line mt-2"></div>
        <div className="col-md-5 mt-2">
          <div className='m-2 p-2'>
            <h2 className='sub-heading mx-4'>Driver Details</h2>
            <div className='row m-2 p-2'>
              <div className="col-md-6">
                <p className='label'>Driver Name</p>
                <p>{booking ? booking.driver_name || '-' : <Skeleton />}</p>
                <p className='label'>Workers Count</p>
                <p>{booking ? booking.workers_count || '-' : <Skeleton />}</p>
              </div>
              <div className="col-md-6">
                <p className='label'>Driver Contact Number</p>
                <p>{booking ? booking.driver_contact || '-' : <Skeleton />}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className='row'>

        <div className="col-md-2 mt-5 mx-4">
          <p className='mx-5'> status:</p>
        </div>

        <div className="col-md-8 m-1 p-1 ">
          <BookingsIndictor currentStep={currentStep} />
        </div>

      </div>
      <hr />
      <div className="row">
        <div className="col-md-6 mt-2">
          <div className='m-2 p-2'>
            <p className='sub-heading'>Service Details</p>
            <div className='row'>
              <div className='col-md-6'>
                <h1 className='label'>Service Name</h1>
                <p className='content'>{booking ? booking.service_name || '-' : <Skeleton />}</p>

                <h1 className='label'>Booking ID</h1>
                <p className='content'>{booking ? booking.display_booking_id|| '-' : <Skeleton />}</p>

                <h1 className='label'>Service Date</h1>
                <p className='content'>{booking ? new Date(booking.service_date).toLocaleDateString() || '-' : <Skeleton />}</p>
              </div>
              <div className='col-md-6'>
                <h1 className='label'>Selected Offerings</h1>
                <p className='content'>{booking ? booking.service_offering_name || '-' : <Skeleton />}</p>

                <h1 className='label'>Booking Date</h1>
                <p className='content'>{booking ? new Date(booking.service_date).toLocaleDateString() || '-' : <Skeleton />}</p>

                <h1 className='label'>Service Time Slot</h1>
                <p className='content'>{booking ? booking.service_time || '-' : <Skeleton />}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 vertical-line mt-3"></div>
        <div className="col-md-5 mt-2">
          <div className='m-2 p-2'>
            <h2 className='sub-heading'>User Details</h2>
            <div className='row'>
              <div className='col-md-6 mt-2'>
                <h1 className='label'>Customer Name</h1>
                <p className='content'>{booking ? booking.user_name || '-' : <Skeleton />}</p>

                <h1 className='label'>Customer’s Email</h1>
                <p className='content'>{booking ? booking.user_email || '-' : <Skeleton />}</p>
              </div>
              <div className='col-md-6 mt-2'>
                <h1 className='label'>Customer’s Contact</h1>
                <p className='content'>{booking ? booking.user_phone_number || '-' : <Skeleton />}</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <hr />
      <div>
        <p className='sub-heading'>  Attachments </p>
        <p className='label'>Description </p>
        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue..</p>
      </div>
      <p className='label'> Attached Pictures </p>
      <div className='d-flex'>
        <img src='https://5.imimg.com/data5/SELLER/Default/2021/8/ZE/DT/FA/902890/new-product-500x500.jpeg' alt='Image 1' style={{ height: '100px', width: '100px', marginRight: '15px' }} className='rounded' />
        <img src='https://content.jdmagicbox.com/v2/comp/hyderabad/q9/040pxx40.xx40.240515144227.u2q9/catalogue/allianz-packers-and-movers-private-limited-trimulgherry-hyderabad-packers-and-movers-i4tzkhtxmn.jpg' alt='Image 2' style={{ height: '100px', width: '100px', marginRight: '15px' }} className='rounded' />
        <img src='https://s3-alpha-sig.figma.com/img/8a4b/3b9a/1cda81d4633fa2aa5c15bee243642663?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XgZi25idSxtfyyXxYn9C43~~Eh~-wWkFTb~xI6zKgLWkdzow6B0ME1emqRJbbD~GqbIRQnY3HfbQsqcpYKml3inz6BZoiOzb0EfTZoKdPQnqyxNHNCIpgg-SUjtVvCUkhEA8bWB~5GwzZ5Jh4p8w7YliTrH7zCRgQ63Qfk3CiipCxfYAI6fOm-VN6RLhqh3yn7es4aVNQrBTIhcDPcC2S-BhT~W0VYNRjlQbDWtge6bywtXAYadrYlWBbn1psGuEBc4ZhGheoiGIVOftBWyvXq-ObN75LQ4pJIeW8EQukgp0uHXRCq6kWuZeiKnFQhxy5Xq6Rpp8cwr5-7e3nGJqpw__' alt='Image 3' style={{ height: '100px', width: '100px', marginRight: '15px' }} className='rounded' />
        <img src='https://content.jdmagicbox.com/comp/bangalore/x5/080pxx80.xx80.170425115137.i4x5/catalogue/sunrise-packers-and-packers-bommanahalli-bangalore-packers-and-movers-within-city-3cw4lp1-250.jpg' alt='Image 4' style={{ height: '100px', width: '100px', }} className='rounded' />

      </div>



      <hr />
      <div className="row">
        <div className="col-md-6 mt-2">
          <div className='m-2 p-2'>
            <p className='sub-heading'>Payment Details</p>
            <div className='row'>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label'>Transaction ID</h1>
                  <p className='content'>{booking ? booking.transaction_id || '-' : <Skeleton />}</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label'> Calculated amount</h1>
                  <p className='content'>$123</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label text-success'>User quoted amount</h1>
                  <p className='content text-success'>6400</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label'>Used coupon Code</h1>
                  <p className='content '>SUM24DEAL (30%)</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label text-danger'>Coupon discount</h1>
                  <p className='content text-danger '> -₹1680</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label '> Margin (10%)</h1>
                  <p className='content  '> ₹640</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label '>Min. price margin</h1>
                  <p className='content '> ₹6000</p>
                </div>
              </div>
              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label '>Max price margin</h1>
                  <p className='content '> ₹6000</p>
                </div>
              </div>

              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label '>Taxes</h1>
                  <p className='content '> ₹640</p>
                </div>
              </div>

              <div className='col-md-10'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h1 className='label '>Convenience/Platform fees</h1>
                  <p className='content '> ₹640</p>
                </div>
              </div>
              <hr />


              <div className='col-md-10 d-flex justify-content-between mx-5'>
                <div className='col-md-5'>
                  <h1 className='label '> Payment Status</h1>
                  <p className='content '>Completed </p>
                </div>
                <div className='col-md-5'>
                  <h1 className='label '>Package Type</h1>
                  <p className='content '> Premium</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 vertical-line mt-3 "></div>
        <div className="col-md-5 mt-2">
          <div className='m-2 p-2'>
            <h2 className='sub-heading'>Moving Locations</h2>
            <div className='row '>
              <div className='col-md-12'>
                <h1 className='label mt-5'> Pickup Location</h1>
                <p className='content'>{booking ? booking.pickup_location || '-' : <Skeleton />}</p>
                <h1 className='label mt-5'>Drop Location</h1>
                <p className='content'>{booking ? booking.drop_location || '-' : <Skeleton />}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;