import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';

const Staff = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || '');
  const [statusFilter, setStatusFilter] = useState(location.state?.statusFilter || '');
  const [roleFilter, setRoleFilter] = useState(location.state?.roleFilter || '');
  const [showFilters, setShowFilters] = useState(Boolean(location.state?.filterApplied));
  const [filterApplied, setFilterApplied] = useState(Boolean(location.state?.filterApplied));
  const [role, setRole] = useState(localStorage.getItem("role"));

  const headers = ['Name', 'Contact', 'Email', 'Role', "Is_Active"];

  const getStaffList = async (data) => {
    setLoading(true);
    try {
        const res = await apicall.post(data, "get_all_staff");
        setStaff(res.data);
    } catch (e) {
        toast.error(e.message.replace('Error:', ''));
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    applyFilters();
  }, []);

  const handleEdit = (staff_data) => {
    navigate(`/staffForm/${staff_data.id}`, { 
      state: { staff_data, searchTerm, statusFilter, roleFilter, filterApplied } 
    });
  };

  const applyFilters = () => {
    const data = {};
    if (statusFilter !== '') {
      data.is_active = statusFilter === "Yes" ? 1 : 0;
      setFilterApplied(true);
    }
    if (roleFilter !== '') {
      data.role = roleFilter === "Admin" ? 1 : 2;
      setFilterApplied(true);
    }
    if (searchTerm !== '') {
      data.search_key = searchTerm.trim().toLowerCase();
    }
    getStaffList(data);
  };

  const clearAllFilters = () => {
    setStatusFilter('');
    setRoleFilter('');
    setFilterApplied(false);
    setShowFilters(false);
    const data = {};
    if (searchTerm !== '') {
      data.search_key = searchTerm.trim().toLowerCase();
    }
    getStaffList(data);
  };

  const toggleFilters = () => setShowFilters(prev => !prev);

  const staffData = staff.map(ad => [
    `${ad.first_name} ${ad.middle_name || ''} ${ad.last_name || ''}`,
    ad.phone_number,
    ad.email,
    ad.role === 1 ? "Admin" : "Customer Support",
    ad.is_active === 1 ? "Yes" : "No"
  ]);

  return (
    <div className="vendor-table-container container mt-3">
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h3 className="heading">Staff</h3>
          <p className="content">Here is a list of all staff</p>
        </div>

        <div className='mx-5 mb-4'>
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: '350px' }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  applyFilters(); 
                }
              }}
            />
            <InputGroup.Text className='primaryBackground' onClick={applyFilters}>
              <FaSearch className='text-white' />
            </InputGroup.Text>
          </InputGroup>
        </div>

        <div className="d-flex align-items-center mb-3">
          <img
            className='mx-2'
            src="/image/filter_active_icon.png"
            alt="Filter Applied"
            style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
          />
          <img
            className='mx-2'
            src="/image/filter_iocn.png"
            alt="Filter"
            style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
            onClick={toggleFilters}
          />
          {role == 1 && 
          <button className="btn Button primaryBackground text-white" onClick={() => navigate('/staffForm/0')}>
            Add Staff
          </button>
          }
        </div>
      </div>

      {showFilters && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex gap-3">
            <DropdownButton id="status-filter" variant="outline-secondary" title={statusFilter || 'Active'}>
              <Dropdown.Item onClick={() => setStatusFilter("Yes")}>Yes</Dropdown.Item>
              <Dropdown.Item onClick={() => setStatusFilter("No")}>No</Dropdown.Item>
            </DropdownButton>

            <DropdownButton id="role-filter" variant="outline-secondary" title={roleFilter || 'Role'}>
              <Dropdown.Item onClick={() => setRoleFilter("Admin")}>Admin</Dropdown.Item>
              <Dropdown.Item onClick={() => setRoleFilter("Customer Support")}>Customer Support</Dropdown.Item>
            </DropdownButton>

            <button className="btn Button primaryBackground text-white" onClick={applyFilters}>Apply</button>
            <button 
              className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" 
              onClick={clearAllFilters}
            >
              Clear All 
            </button>
          </div>
        </div>
      )}

      {loading ? (
        <Skeleton count={5} height={40} />
      ) : (
         role == 1 ? 
        <Table headers={headers} data={staffData} edit={(index) => handleEdit(staff[index])} pagination={true} recordsCount={10} /> :
        <Table headers={headers} data={staffData} pagination={true} recordsCount={10} />
      )}
    </div>
  );
};

export default Staff;