import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5'; 
import Table from '../../components/Table';
import Skeleton from 'react-loading-skeleton';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
//import ServiceOfferingForm from './ServiceOfferingForm';
import { Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../components/Loader';


const ServiceOfferingsList = (route) => {


  const { service_id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [serviceName,setServiceName] = useState(state?.service_name)
  const [offerings, setOfferings] = useState([]);
  const [serviceTiers, setServiceTiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTiers, setLoadingTiers] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ id: '', title: '', description: '' });
  const [isSaving, setIsSaving] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));
  

  const headers = ['Name','Icon','Short Description', 'Description', 'Image'];
 const tierHeaders = ['Label', 'Description'];

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
});

  const getServiceOfferingsList = async () => {
    try {
      const res = await apicall.get(`service_offerings/${service_id}`);
      setOfferings(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const getServiceTiersList = async () => {
    try {
      const res = await apicall.get(`service_tier/${service_id}`);
      setServiceTiers(res.data);
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred');
    } finally {
      setLoadingTiers(false);
    }
  };

  useEffect(() => {
      getServiceOfferingsList();
      getServiceTiersList();
  }, []);

  const handleEditOffering = (offering) => {
    navigate('/service_offerings_form/'+offering.id, { state: { serviceName, offering, mode: 'edit',service_id } });
  };

  const handleEditTier = (index) => {
    const tier = serviceTiers[index];
    if (tier) {
      setModalData({ id: tier.id, title: tier.title, description: tier.description });
      setShowModal(true);
    } else {
      console.error(`Tier with index ${index} not found`);
    }
  };

  const handleAdd = () => {
    navigate('/service_offerings_form/0', { state: { serviceName, mode: 'add',service_id } });
  };

  const handleModalSave = async (values) => {
    setIsSaving(true); 
    try {
      const data = {
        id: modalData.id,
        title: values.title,
        description: values.description,
      };

      await apicall.post(data, `service_tier`);
      toast.success('Packaging type updated successfully!');
      setShowModal(false);
      getServiceTiersList();
    } catch (e) {
      toast.error(e.message?.replace('Error:', '') || 'An error occurred while updating');
    } finally {
      setIsSaving(false); 
    }
  };

  const offeringsData = offerings.map(({ id, icon, name, short_description, description, description_image }) => [
    name,
    <img src={icon} alt={name} className="img-thumbnail" width="30" height="30"/>,
    short_description,
    description != "null" ? description : "",
    <img src={description_image} alt="Description" className="img-thumbnail" width="60" height="60"/>,
  ]);

  const tiersData = serviceTiers.map(({ id, title, description }) => [
    title,
    description,
  ]);

  return (
    <div className="container my-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h3 className="heading ml-2"> <span className="me-4 pointer"><IoArrowBack size={24}onClick={() => navigate('/services')} /></span>Service Offerings for <span className='primaryColorText'>{serviceName}</span></h3>
              <p className="content mx-5 mt-3">Here is a list of all service offerings</p>
            </div>
            { role == 1 &&
            <button className="btn primaryBackground border-0 text-white mb-4" onClick={handleAdd}>
              Add Offerings
            </button>
            }
          </div>
          {loading ? (
            <Skeleton count={7} height={40} />
          ) : (
            role == 1 ? 
            <Table headers={headers} data={offeringsData} edit={(index) => handleEditOffering(offerings[index])} pagination={false} recordsCount={6} />
            :
            <Table headers={headers} data={offeringsData} pagination={false} recordsCount={6} />
          )}
          <div className="mt-4">
            <h3 className="heading">Packaging Type</h3>
            <p className="content">Here is a list of all service tiers</p>
          </div>
          {loadingTiers ? (
            <Skeleton count={3} height={40} />
          ) : (
            role == 1 ? 
            <Table headers={tierHeaders} data={tiersData} edit={handleEditTier} pagination={true} recordsCount={5} /> :
            <Table headers={tierHeaders} data={tiersData}  pagination={true} recordsCount={5} /> 

          )}

      <Modal show={showModal} onHide={() => setShowModal(false)} className='mt-5 '>
        <Modal.Header closeButton>
          <Modal.Title>Edit Packaging Type</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            title: modalData.title,
            description: modalData.description,
          }}
          validationSchema={validationSchema}
          onSubmit={handleModalSave}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                  <label className='label'>Title</label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3">
                  <label className='label'>Description</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <button
                  className="btn Button primaryColorText btn-outline-secondary me-2"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn Button primaryBackground text-white position-relative  d-flex justify-content-center align-items-center"
                  onClick={handleSubmit}
                >
                  {isSaving ? (
                    <Loader style={{ height: "30", width: "30" }} /> 
                  ) : (
                    "Save"
                  )}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ServiceOfferingsList;