import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { VendorBasicDetails } from './VendorBasicDetails';
import VendorPricing from './VendorPricing';
import VendorFeedback from './VendorFeedback';
import { IoArrowBack } from 'react-icons/io5';
import * as apicall from '../../components/Apicall';
import Table from '../../components/Table';
import Skeleton from '../../components/SekeltonLoder';
import { toast } from 'react-toastify';

const VendorDetails = () => {
  const serviceHeaders = ['Service Type', 'Selected Offerings', 'Booking Date', 'User Name', 'Amount', 'Status'];
  const [serviceData, setServiceData] = useState([]);
  const [loadingServiceHistory, setLoadingServiceHistory] = useState(true);

  const navigate = useNavigate();
  const { vendor_id } = useParams();
  const location = useLocation();
  const { searchTerm, statusFilter, ratingFilter, serviceTypeFilter, filterApplied } = location.state || {};
  const [vendorData, setVendorData] = useState();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getVendorDetails = async () => {
    try {
      const res = await apicall.get(`vendorDetails/${vendor_id}`);
      setVendorData(res.data[0]);
    } catch (error) {
      console.error('Failed to fetch vendor details:', error);
      toast.error('Failed to load vendor details.');
    }
  };

  const getVendorServiceHistory = async () => {
    setLoadingServiceHistory(true);
    try {
      const res = await apicall.get(`vendor_bookings_history/${vendor_id}`);
      const formattedData = res.data.map(({ service_name, service_offering_name, service_date, service_time, vendor_payout_amount, status }) => [
        service_name || '-',
        service_offering_name || '-',
        formatDate(service_date),
        service_time || '-',
        `₹${vendor_payout_amount}` || '-',
        status || '-',
      ]);
      setServiceData(formattedData);
    } catch (error) {
      console.error('Failed to fetch service history:', error);
      toast.error('Failed to load service history.');
    } finally {
      setLoadingServiceHistory(false);
    }
  };

  useEffect(() => {
    getVendorDetails();
    getVendorServiceHistory();
  }, []);

  const handledetails = () => {
    navigate(`bookingDetails`);

  }



  return (
    <div className="container bg-white my-4 p-3 shadow rounded-3">
      <div className="mb-2 d-flex align-items-center ">
        <IoArrowBack
          size={24}
          className="me-2 pointer"
          onClick={() =>
            navigate('/vendors', {
              state: {
                searchTerm,
                statusFilter,
                ratingFilter,
                serviceTypeFilter,
                filterApplied,
              },
            })
          }
        />
        <h1 className="sub-heading mb-0">Vendor Details</h1>
      </div>

      {vendorData ? (

        <div>
          <VendorBasicDetails className="mt-2 mb-2" vendorData={vendorData} />
          <VendorPricing className="mt-2 mb-2" vendorData={vendorData} vendor_id={vendor_id} />

          <div className="row mb-3 secondary">
            <h1 className="sub-heading mt-3">Service History</h1>
            {loadingServiceHistory ? (
              <Skeleton height="30px" count={5} />
            ) : (
              <Table headers={serviceHeaders} data={serviceData} pagination={true} recordsCount={10} details={handledetails} />
            )}
          </div>
          {vendorData.vendor_ratings != null &&
            <VendorFeedback className="mt-2 mb-2" vendor_id={vendor_id} vendor_ratings={vendorData.vendor_ratings} />
          }
        </div>
      ) : (
        <Skeleton height="30px" count={5} />
      )}
    </div>
  );
};

export default VendorDetails;