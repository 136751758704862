import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as apicall from '../../components/Apicall';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const CouponsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceName } = location.state || {};
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const fileInputImageRef = useRef(null);

  const IMAGE_SIZE_LIMIT = 500 * 1024; 
  const IMAGE_DIMENSIONS_WIDTH = 720;
  const IMAGE_DIMENSIONS_HEIGHT = 720;

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const res = await apicall.get('services');
        if (res.success) {
          setServices(res.data);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error('Failed to load services');
      }
    };

    fetchServices();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required('Coupon Code is required'),
    shortDescription: Yup.string().required('Promotion is required'),
    startDate: Yup.date().required('Valid From is required'),
    endDate: Yup.date()
      .required('Valid Until is required')
      .min(Yup.ref('startDate'), 'End Date can’t be before Start Date'),
    discount: Yup.number()
      .required('% Off is required')
      .min(0, 'Must be at least 0%')
      .max(100, 'Cannot exceed 100%'),
    service: Yup.string().required('Service type is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      shortDescription: '',
      startDate: '',
      endDate: '',
      discount: '',
      service: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!imagePreview) {
        toast.error('Please upload an image');
        return;
      }

      if (!selectedService) {
        toast.error('Please select a service');
        return;
      }

      setLoading(true);
      try {
        const data = {
          code: values.name,
          start_date: values.startDate,
          end_date: values.endDate,
          service: selectedService, 
          value: values.discount, 
          description: values.shortDescription,
          image: imagePreview,
        };

        const res = await apicall.post(data, '/coupons'); 
        if (res.success) {
          toast.success(res.message);
          handleCancel();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message.replace('Error:', ''));
      } finally {
        setLoading(false);
      }
    },
  });

  const getInputClass = (field) => {
    return formik.touched[field] && formik.errors[field]
      ? 'form-control is-invalid'
      : 'form-control';
  };

  const handleImageUpload = () => {
    fileInputImageRef.current?.click();
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImagePreview(reader.result);
    };
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        toast.error('Image file is too large');
        return;
      }
      let newImage = new Image();
      newImage.src = URL.createObjectURL(file);
      newImage.onload = function () {
        let w = newImage.width;
        let h = newImage.height;
        if (w <= IMAGE_DIMENSIONS_WIDTH && h <= IMAGE_DIMENSIONS_HEIGHT) {
          convertToBase64(file);
        } else {
          toast.error('Image dimensions are too large');
        }
      };
    }
  };

  const handleCancel = () => {
    navigate('/coupons', { state: { service_name: serviceName } });
  };

  const handleServiceSelect = (serviceId) => {
    if (selectedService === serviceId) {
      setSelectedService(null);
      formik.setFieldValue('service', '');
      formik.setFieldTouched('service', true);
    } else {
      setSelectedService(serviceId);
      formik.setFieldValue('service', serviceId);
      formik.setFieldTouched('service', true);
    }
  };

  return (
    <div className="container bg-white p-3 mt-4">
      <h1 className="heading">
        <span className="me-4 pointer"><IoArrowBack size={24} onClick={handleCancel} /></span>
        Add a Coupon <span className="primaryColorText">{serviceName}</span>
      </h1>

      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3 mt-4">
          <div className="col-md-4">
            <label htmlFor="name" className="label">Coupon Code</label>
            <input
              type="text"
              id="name"
              name="name"
              className={getInputClass('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.name}</div>
          </div>
          <div className="col-md-8">
            <label htmlFor="shortDescription" className="label">PROMOTION</label>
            <input
              type="text"
              id="shortDescription"
              name="shortDescription"
              className={getInputClass('shortDescription')}
              value={formik.values.shortDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.shortDescription}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="label">Valid From</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className={getInputClass('startDate')}
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.startDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="endDate" className="label">Valid Until</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className={getInputClass('endDate')}
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.endDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="discount" className="label">% Off</label>
            <input
              type="number"
              id="discount"
              name="discount"
              className={getInputClass('discount')}
              value={formik.values.discount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="invalid-feedback">{formik.errors.discount}</div>
          </div>
        </div>

        <div className='row'>
          <label htmlFor="service" className="label mt-2">Select Type of Services</label>
          <div className="d-flex flex-wrap mb-4 mt-3 me-2">
            {services.map(service => (
              <button
                key={service.id}
                type="button"
                className={`btn primaryColorText btn-outline-secondary text-dark me-3 ${selectedService === service.id ? 'active text-white' : ''}`}
                onClick={() => handleServiceSelect(service.id)}
              >
                {service.name}
              </button>
            ))}
          </div>
          {formik.touched.service && formik.errors.service && (
            <div className="invalid-feedback d-block">{formik.errors.service}</div>
          )}
        </div>

        <div className="form-group mb-4 ">
          <div className="d-flex align-items-center">
            <div>
              {imagePreview ? (
                <div className="d-flex align-items-center">
                  <img
                    src={imagePreview}
                    alt="Uploaded Image"
                    className="img-fluid me-3 uploaded-image"
                    height={100}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => setImagePreview(null)}
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleImageUpload}
                >
                  Upload Image
                </button>
              )}
              <input
                type="file"
                accept="image/*"
                ref={fileInputImageRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
        
        <div className='d-flex justify-content-center mt-5 mb-4'>
          <button
            type="button"
            className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn Button primaryBackground text-center text-white position-relative d-flex justify-content-center align-items-center"
          >
            {loading ? <Loader style={{ height: "30", width: "40" }} /> : 'Save'}
          </button>

        </div>

      </form>
      <ToastContainer />
    </div>
  );
};

export default CouponsForm;