import React, { useState, useEffect, useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const BookingsList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [loadingBookings, setLoadingBookings] = useState(true);
    const [statusList, setStatusList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [services, setServices] = useState([]);
    const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || '');
    const [statusFilter, setStatusFilter] = useState(location.state?.statusFilter || null);
    const [serviceFilter, setServiceFilter] = useState(location.state?.serviceFilter || null);
    const [dateFilter, setDateFilter] = useState(location.state?.dateFilter || null);
    const [filterApplied, setFilterApplied] = useState(
        Boolean(location.state?.statusFilter || location.state?.serviceFilter || location.state?.dateFilter)
    );

    const bookingHeaders = [
        'Service Name',
        'Booking ID',
        'Customer Name',
        'Vendor Name',
        'Service Date',
        'Status',
    ];

    const handleError = (error) => {
        toast.error(error.message.replace('Error:', ''));
    };

    const fetchFilters = useCallback(async () => {
        try {
            const [servicesRes, statusRes] = await Promise.all([
                apicall.get('services'),
                apicall.get('bookingStatus'),
            ]);
            setServices(servicesRes.data);
            setStatusList(statusRes.data);
        } catch (e) {
            handleError(e);
        }
    }, []);

    const fetchBookings = async () => {
        setLoadingBookings(true);
        try {
            const res = await apicall.post({}, 'bookingsList');
            if (res.success) {
                setBookings(res.data);
                setFilteredBookings(res.data);
            } else {
                toast.error(res.message || 'Failed to fetch bookings.');
            }
        } catch (e) {
            toast.error(e.response?.data?.message || 'An error occurred while fetching bookings.');
        } finally {
            setLoadingBookings(false);
        }
    };

    const applyFilters = () => {
        const filtered = bookings.filter((booking) => {
            const matchesService = !serviceFilter || booking.service_name === serviceFilter.name;
            const matchesStatus = !statusFilter || booking.status === statusFilter.name;
            const matchesDate = !dateFilter ||
                new Date(booking.serviceDate).toLocaleDateString() === new Date(dateFilter).toLocaleDateString();
            return matchesService && matchesStatus && matchesDate;
        });
        setFilteredBookings(filtered);
        setFilterApplied(Boolean(serviceFilter || statusFilter || dateFilter));
        setShowFilters(true);
    };

    const clearAllFilters = () => {
        setStatusFilter(null);
        setServiceFilter(null);
        setDateFilter(null);
        setSearchTerm('');
        setFilterApplied(false);
        setShowFilters(false);
        setFilteredBookings(bookings); // Reset to unfiltered bookings
    };

    const handleDetails = (index) => {
        const booking = filteredBookings[index];
        navigate(`/bookingDetails/${booking.booking_id}`, {
            state: {
                bookingId: booking.booking_id,
                searchTerm,
                statusFilter,
                serviceFilter,
                dateFilter,
                filterApplied,
            },
        });
    };

    const handleSearch = useCallback(() => {
        const dataToSearch = filterApplied ? filteredBookings : bookings;
        const searchFiltered = dataToSearch.filter((booking) =>
            Object.values(booking).some((value) =>
                value?.toString().toLowerCase().includes(searchTerm.trim().toLowerCase())
            )
        );
        setFilteredBookings(searchFiltered);
    }, [searchTerm, bookings, filteredBookings, filterApplied]);

    const toggleFilters = () => {
        setShowFilters(!showFilters || filterApplied);
    };

    useEffect(() => {
        fetchBookings();
        fetchFilters();
    }, [fetchFilters]);

    useEffect(() => {
        if (filterApplied || searchTerm) {
            applyFilters();
        }
    }, [filterApplied, searchTerm, bookings]);

    const bookingsData = filteredBookings.map((booking) => [
        booking.service_name,
        booking.display_booking_id,
        booking.user_name,
        booking.vendor_name || '-',
        new Date(booking.serviceDate).toLocaleDateString(),
        booking.status,
    ]);
    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h3>Bookings</h3>
                    <p className="content mb-3">Here is a list of all Bookings</p>
                </div>
                <div className="mx-5 mb-4">
                    <InputGroup>
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ width: '350px' }}
                        />
                        <InputGroup.Text className="primaryBackground" onClick={handleSearch}>
                            <FaSearch className="text-white" />
                        </InputGroup.Text>
                    </InputGroup>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <img
                        className="mx-2"
                        src="/image/filter_active_icon.png"
                        alt="Filter Applied"
                        style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                    <img
                        className="mx-2"
                        src="/image/filter_iocn.png"
                        alt="Filter"
                        style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
                        onClick={toggleFilters}
                    />
                </div>
            </div>
            {showFilters && (
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex gap-3">
                        <DropdownButton
                            id="service-filter"
                            variant="outline-secondary"
                            title={serviceFilter?.name || 'Select Service'}
                        >
                            {services.map((service) => (
                                <Dropdown.Item
                                    key={service.id}
                                    onClick={() => setServiceFilter(service)}
                                >
                                    {service.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <DropdownButton
                            id="status-filter"
                            variant="outline-secondary"
                            title={statusFilter?.name || 'Select Status'}
                        >
                            {statusList.map((status) => (
                                <Dropdown.Item
                                    key={status.id}
                                    active={status.id === statusFilter?.id}
                                    onClick={() => setStatusFilter(status)}
                                >
                                    {status.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <div style={{ position: 'relative' }}>
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => setShowDatePicker(!showDatePicker)}
                            >
                                {dateFilter
                                    ? new Date(dateFilter).toLocaleDateString()
                                    : 'Select Booking Date'}
                            </button>
                            {showDatePicker && (
                                <div style={{ position: 'absolute', zIndex: 1000 }}>
                                    <DatePicker
                                        selected={dateFilter}
                                        onChange={(date) => {
                                            setDateFilter(date);
                                            setShowDatePicker(false);
                                        }}
                                        className="form-control"
                                        dateFormat="yyyy/MM/dd"
                                        isClearable
                                        inline
                                    />
                                </div>
                            )}
                        </div>
                        <button className="btn Button primaryBackground text-white" onClick={applyFilters}>
                            Apply
                        </button>
                        <button
                            className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect"
                            onClick={clearAllFilters}
                        >
                            Clear All
                        </button>
                    </div>
                </div>
            )}

            {loadingBookings ? (
                <Skeleton count={5} height={40} />
            ) : (
                <Table
                    headers={bookingHeaders}
                    data={bookingsData}
                    pagination={true}
                    recordsCount={10}
                    details={handleDetails}
                />
            )}
        </div>
    );
};

export default BookingsList;