import React, { useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';
import Loader from '../../components/Loader';

const InquiryDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const inquiry = state?.inquiry || {};
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await apicall.get(`/delete_inquiry/${inquiry.id}`);
      toast.success('Inquiry deleted successfully!');
      navigate('/inquiries', { state: { ...state } });
    } catch (error) {
      toast.error(error.message || 'An error occurred while deleting the inquiry.');
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className="container my-4 p-3 shadow rounded-3 bg-white">
      <div className="mb-2 d-flex align-items-center">
        <IoArrowBack size={24} className="me-2 pointer" onClick={() => navigate('/inquiries', { state: { ...state } })} />
        <h1 className="sub-heading mb-0">
          {inquiry?.type === 1 ? "General" : inquiry?.type === 2 ? "Partner" : "Career"} Inquiry
        </h1>
      </div>

      <div className="row mb-3 Secondary-Variant">
        <div className="col-12 p-4">
          <div className="row">
            <div className="col-4 mb-2">
              <p className="label mb-1">Name</p>
              <p className="content mb-1">{inquiry?.name || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Contact</p>
              <p className="content mb-1">{inquiry?.mobile || 'N/A'}</p>
            </div>
            <div className="col-4 mb-2">
              <p className="label mb-1">Email</p>
              <p className="content mb-1">{inquiry?.email || 'N/A'}</p>
            </div>
          </div>

          {inquiry?.type === 1 && (
            <div className="row mb-2 mt-3">
              <div className="col-12 mb-2">
                <p className="label mb-1">Message</p>
                <p className="content mb-1">{inquiry?.message || 'No message provided.'}</p>
              </div>
            </div>
          )}

          {inquiry?.type === 2 && (
            <div className="row mb-2 mt-3">
              <div className="col-12 mb-2">
                <p className="label mb-1">Company Name</p>
                <p className="content mb-1">{inquiry?.company_name || 'N/A'}</p>
              </div>
            </div>
          )}

          {inquiry?.type === 3 && (
            <div className="row">
              <div className="col-4 mb-2">
                <p className="label mb-1">Applying for</p>
                <p className="content mb-1">{inquiry?.job_role || 'N/A'}</p>
              </div>
              <div className="col-4 mb-2">
                <p className="label mb-1">Current/Previous Job Title</p>
                <p className="content mb-1">{inquiry?.job_title || 'N/A'}</p>
              </div>
            </div>
          )}

          {role === "1" && (
            <div>
              <button
                type="button"
                className="btn primaryColorText btn-outline-danger mb-2 mt-2"
                onClick={handleDeleteClick}
              >
                <FaTrash /> Delete
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={handleDeleteCancel}
        centered
        size="xxl"
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <div className="d-flex flex-column justify-content-center align-items-center p-1 m-5">
          <p id="delete-confirmation-description" className="text-center mb-3">
            Are you sure you want to delete this inquiry?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
            <button
              className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
              onClick={handleDeleteConfirm}
            >
              {loading ? <Loader style={{ height: "30", width: "40" }} /> : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InquiryDetails;