import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import * as apicall from '../../components/Apicall';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MasterDataList = () => {
  const [masterData, setMasterData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const masterDataHeaders = ['Key', 'Value'];

  const getMasterData = async () => {
    try {
      const response = await apicall.get('/masterData');
      if (response?.data?.length) {
        setMasterData(response.data);
      } else {
        toast.warning('No master data found.');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch master data.');
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  const masterDataRows = masterData.map(({ key, value }) => [key, value]);

  return (
    <div className="container my-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="heading ml-2">Master Data</h3>
      </div>
      {loadingData ? (
        <Skeleton height={30} count={5} style={{ marginBottom: '10px' }} />
      ) : (
        <Table headers={masterDataHeaders} data={masterDataRows} pagination={true} recordsCount={10} />
      )}
    </div>
  );
};

export default MasterDataList;