import * as constants from '../constants/Constants';

export const verifyOTP = async (data) => {
  try {
    const response = await fetch(constants.apiUrl + 'api/v1/verifyOTP', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();
    if (resData.status != 200 || resData.success != true) {
      let message = resData.message ? resData.message : resData.errors[0].msg;
      throw new Error(message);
    } else {
      localStorage.setItem("token", resData.token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      localStorage.setItem("role",resData.role);
      return resData;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const regenerateToken = async (method,url,data) => { 
  try {
    var refreshToken = localStorage.getItem("refresh_token")
    const response = await fetch(constants.apiUrl + 'api/v1/refreshToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'isMobile':false
      },
      body: JSON.stringify({
        refreshToken:refreshToken
      }),
    });
    const resData = await response.json();
    if (resData.status != 200 || resData.success != true) {
      localStorage.clear();
      window.location.href = "/login"
    } else {
      localStorage.setItem("token", resData.token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      if(method == "GET"){
        get(url)
      }else if(method == "POST"){
        post(data,url);
      }else{
        localStorage.clear();
        window.location.href = "/login"
      }
    }
  } catch (error) {
    localStorage.clear();
    window.location.href = "/login"
  }
}


  export const get = async (url) => {
    try {
      var token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      const response = await fetch(constants.apiUrl + 'api/v1/' + url, {
        headers: {
          'Content-Type': 'application/json',
          'isMobile':false,
          Authorization: 'Bearer ' + token,
        },
      });
      let json = await response.json();
      if(json.status == 401){
        regenerateToken("GET",url,null);
      }else if(json.status != 200 || json.success != true){
        let message = json.message ? json.message : json.errors[0].msg;
        throw new Error(message);
      }else{
        return json;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  export const post = async (data,url) => {
    try {
      var token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
      const response = await fetch(constants.apiUrl + 'api/v1/'+url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'isMobile':false,
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      });
      const resData = await response.json();
      if(resData.status == 401){
        regenerateToken("POST",url,data);
      }else if(resData.status != 200 || resData.success != true){
        let message = resData.message ? resData.message : resData.errors[0].msg;
        throw new Error(message);
      }else{
        return resData;
      }
    } catch (error) {
      throw new Error(error);
    }
  };