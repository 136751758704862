import React from 'react'

const VendorFeedback = () => {
  return (
    <div>
       <div className="row secondary ">
                <div className="col-12 Secondary-Variant py-4 ">
                    <h1 className="sub-heading mt-2 mb-3">Customer’s Feedback</h1>

                    <h1 className="label  mt-2">Rating</h1>
                    <div className="d-flex">
                        <p className="content mt-2">4.0</p>
                        <p className="ms-2 mt-2">⭐⭐⭐⭐</p>
                    </div>

                </div>


            </div>
            <div className="row mt-3 align-items-start">
                <div className="col-auto">
                    <div
                        className="bg-primary p-2  text-white rounded d-flex align-items-center justify-content-center"
                    // style={{ width: '44px', height: '44px' }}
                    >
                        A
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="mb-1 content">Paityn Lipshutz</h1>
                            <p className="label mb-1">Service: Packers & Movers</p>
                        </div>
                        <div className="text-end label">02 days ago</div>
                    </div>
                </div>
            </div>

            <div className="mt-2 border-bottom">
                <div className="d-flex align-items-center">
                    <span className="content text-start">4.0</span>
                    <span className="text-warning mx-2">⭐ ⭐ ⭐ ⭐</span>
                </div>
                <p className="mt-2 content">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit. Ut et massa mi.
                    Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis
                    ultrices mauris. Maecenas vitae.
                </p>
            </div>


            <div className="row mt-3 align-items-start ">
                <div className="col-auto">
                    <div
                        className="bg-danger p-2 text-white rounded d-flex align-items-center justify-content-center"
                    // style={{ width: '44px', height: '44px' }}
                    >
                        S
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="mb-1 content">John Doe</h1>
                            <p className="label mb-1">Service: Packers & Movers</p>
                        </div>
                        <div className="text-end label">01 week ago</div>
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <div className="d-flex align-items-center">
                    <span className="content text-start">3.5</span>
                    <span className="text-warning ms-2">⭐ ⭐ ⭐ ⭐</span>
                </div>
                <p className="mt-2 content">
                    Great service but arrived late. Overall, satisfied with the experience.
                </p>
            </div>
    </div>
  )
}

export default VendorFeedback