import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { VendorBasicDetails } from './VendorBasicDetails';
import VendorPricing from './VendorPricing';
import VendorServiceHistory from './VendorServiceHistory';
import VendorFeedback from './VendorFeedback';
import { IoArrowBack } from 'react-icons/io5';
import * as apicall from '../../components/Apicall';

const VendorDetails = () => {

  const navigate = useNavigate();
  const { vendor_id } = useParams();

  const [vendorData, setVendorData] = useState();

  const getVendorDetails = async () => {
    try {
      const res = await apicall.get("vendorDetails/" + vendor_id);
      setVendorData(res.data[0]);
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      //setloader(false)
    }
  }

  useEffect(() => {
    getVendorDetails()
  }, []);

  return (
    <div className="container bg-white my-4 p-3 shadow rounded-3">
      <div className="mb-2 d-flex align-items-center ">
        <IoArrowBack size={24} className="me-2 pointer" onClick={() => navigate('/vendors')} />
        <h1 className="sub-heading mb-0">Vendor Details</h1>
      </div>

      {vendorData &&
        <div>
          <VendorBasicDetails className='mt-2 mb-2'
            vendorData={vendorData}
          />
          <VendorPricing className='mt-2 mb-2' 
            vendorData={vendorData}
            vendor_id={vendor_id}
          />
          <VendorServiceHistory className='mt-2 mb-2' />
          <VendorFeedback ClassName='mt-2 mb-2' />
        </div>
      }

    </div>
  );
};

export default VendorDetails;