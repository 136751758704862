import React from 'react';

const Skeleton = ({ width = '100%', height = '20px', marginBottom = '10px' }) => {
  const style = {
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    width,
    height,
    marginBottom
  };

  return <div style={style}></div>;
};

export default Skeleton;